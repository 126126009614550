import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography , Chip} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  tagStyle: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main, 
    color: theme.palette.primary.contrastText, 
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.875rem'
  },
  tagStyle1: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: "#008000", 
    color: theme.palette.primary.contrastText, 
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.875rem',
    cursor: 'pointer',
  }
}));

const Profile = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const user = {
    name: localStorage.getItem('user_name') || 'Goodspace User',
    avatar: localStorage.getItem('user_imageId'),
    bio: localStorage.getItem('user_email'),
    type: localStorage.getItem('user_type') || 'ADMIN',
    adminId: localStorage.getItem('admin_id')
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar} 
        to="/settings"
      />
      <Typography className={classes.name} variant="h4">
        {user.name}
      </Typography>
      {user.bio && (
        <Typography variant="body2">{user.bio}</Typography>
      )}
      <Chip
        label={user.type}
        size="small"
        color="primary"
        className={classes.tagStyle}
      />
      {
        user.type === 'ADMIN' && 
        <Chip
          label="MANAGE ADMINS"
          size="small"
          color="primary"
          className={classes.tagStyle1}
          component={RouterLink}
          to="/manage-gs-admins"
        />
      }
      
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
