import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { TotalUsers } from 'views/Dashboard/components';
import EditJobPostV2 from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/EditJobPostV2';
import Pagination from '@material-ui/lab/Pagination';
import styles from './JobCard.module.scss';
import Axios from 'axios';
import { useJobBoostMaster } from '../../contexts/JobBoostMasterContext';
import TextField from '@mui/material/TextField';
import Loader from 'components/Loader';
import { esocialUrl } from 'config';
import {
  FaClipboard,
  FaLink,
  FaEye,
  FaGift,
  FaMoneyBillAlt,
  FaGgCircle,
  FaLaptop,
  FaAndroid,
  FaApple
} from 'react-icons/fa';
import { Grid } from '@material-ui/core';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { sendHttpCall } from 'shareService/httpService';
import { object } from 'underscore';

const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatTime = (time) => {
  const date = new Date(time);
  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};

const notLoggedInTokenProd =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY';

function ToggleStatusModal({
  isOpen,
  onRequestClose,
  confirmAction,
  candidateName,
  isShortlisting
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="active_jobs__modal-modal"
      overlayClassName="active_jobs__modal-overlay">
      <div className="active_jobs__modal2">
        <div className="active_jobs__modal-heading">
          {' '}
          MANUAL APPLICATION STATUS UPDATE
        </div>
        Are you sure you want to
        <span
          style={{
            color: isShortlisting ? '#297bca' : '#ff4e4e',
            paddingLeft: '0.4rem'
          }}>
          {isShortlisting ? 'shortlist' : 'reject'}{' '}
        </span>
        {candidateName}?
        <div className="active_jobs2__modal-btns">
          <button
            className="active_jobs__modal-btn-confirm"
            onClick={confirmAction}>
            Confirm
          </button>
          <button
            className="active_jobs__modal-btn-cancel"
            onClick={onRequestClose}>
            Cancel
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

function FeedbackModal({ isOpen, onRequestClose, feedback, prompt, status }) {
  const getStatusStyles = () => {
    if (status === 'REJECTED') {
      return {
        background: '#fde8e9',
        color: '#ff3a30'
      };
    } else if (status === 'ACCEPTED') {
      return {
        background: '#e7f0fe',
        color: '#297BCA'
      };
    }
    return {};
  };

  const statusStyles = getStatusStyles();
  const createMarkup = (htmlContent) => ({ __html: htmlContent });
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="active_jobs__modal-modal"
      overlayClassName="active_jobs__modal-overlay">
      <div className="active_jobs__modal2">
        <div className={styles.modalHeading}>AI Shortlister Feedback</div>
        <div
          className={styles.modalTextContent}
          dangerouslySetInnerHTML={createMarkup(feedback)}
          style={statusStyles}
        />

        <div className={styles.modalHeading}>AI System Prompt</div>
        {/* <div className={styles.modalSubHeading}>System Prompt</div> */}
        <div className={styles.modalTextContent}>{prompt.system}</div>
        <div className={styles.modalHeading}>AI User Prompt</div>
        <div className={styles.modalTextContent}>{prompt.user}</div>

        <div className="active_jobs2__modal-btns">
          <button
            className="active_jobs__modal-btn-cancel"
            onClick={onRequestClose}>
            Close
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
const manualShortlister = (offerId, candidateId, status) => {
  const shortListData = {
    userId: candidateId,
    status: status
  };
  return new Promise((resolve, reject) => {
    Axios.post(
      `${esocialUrl}/offer/${offerId}/apply/admin-response`,
      shortListData,
      {
        headers: {
          authorization: notLoggedInTokenProd
        }
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error('Error in manualShortlister', e.response);
        reject(e);
      });
  });
};

export default function JobCard({
  data,
  status,
  refreshData,
  deleteJob,
  rejectJob,
  approveJob,
  setJobOnHold,
  showToast,
  currentPage,
  boostJob,
  toggleAutoRecruiter,
  hideButtons
}) {
  const [applications, setApplications] = useState([]);
  const [viewCandidates, setViewCandidates] = useState(false);
  const [viewStats, setViewStats] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(10);
  const [goToPage, setGoToPage] = useState('');
  const [showBoostJobModal, setShowBoostJobModal] = useState(false);
  const [editJob, setEditJob] = useState(false);
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectReasonCustom, setRejectReasonCustom] = useState('');
  const [showRejectJobModal, setShowRejectJobModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checked, setChecked] = useState(!!data?.autoRecruiterEnabled);
  const [applicationsLoading, setApplicationsLoading] = useState(false);
  const [user8, setUser8] = useState(null);
  const [status3Ft, setStatus3Ft] = useState(null);
  const [isStatus3FtLoading, setIsStatus3FtLoading] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState({});
  const [isShortlisting, setIsShortlisting] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState('');
  const [applicationStatus, setApplicationStatus] = useState('');
  const [whatsappStats, setWhatsappStats] = useState('');
  const [emailStats, setEmailStats] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  //Application states
  const [applicationState, setApplicationState] = useState({});
  const [viewApplicationStates, setViewApplicationStates] = useState(false);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      fetchApplications(data.offerId, 1, searchTerm);
      setPage(1);
    }
  };

  const { isOpen, openBoostDrawer, closeBoostDrawer } = useJobBoostMaster();

  const handleToggleStatus = (candidate) => {
    setCurrentCandidate(candidate);
    setIsShortlisting(candidate.request_status !== 'ACCEPTED');
    setShowConfirmation(true);
  };
  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const userType = localStorage.getItem('user_type');
  const adminId = localStorage.getItem('admin_id');

  const handleGoToPage = () => {
    let pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
      setPage(pageNumber);
      setGoToPage('');
    } else {
      alert('Please enter a valid page number');
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    fetchApplications(data.offerId, value);
  };

  const fetchApplications = async (offerId, page = 1, searchTerm = '') => {
    try {
      setApplicationsLoading(true);
      const response = await sendHttpCall(
        'GET',
        `/jobs/get_applications?offerId=${offerId}&page=${page}&searchTerm=${searchTerm}`,
        null
      );

      if (response) {
        setApplications(response.applications);
        setTotalCount(response.total);
        setLastPage(Math.ceil(response.total / 10));
        setViewCandidates(true);
      } else if (response.status === 400) {
        showToast(response.message, 'error');
      } else {
        throw new Error(response.message || 'Failed to fetch applications');
      }

      setApplicationsLoading(false);
    } catch (error) {
      setApplications([])
      console.error('Error fetching applications:', error);
      showToast(error.message || 'Failed to fetch applications.', 'error');
      setApplicationsLoading(false);
    }
  };

  const fetchStats = async (offerId) => {
    try {
      const response = await sendHttpCall('GET', `/job/stats/${offerId}`);

      console.log('Response received:', response);

      // Assuming response is structured as expected
      if (response && response.whatsappStats) {
        setWhatsappStats(response.whatsappStats);
        setEmailStats(response.emailStats);
        setViewStats(true);
      } else {
        showToast('No stats available.');
      }
    } catch (error) {
      console.error('Error fetching communication stats:', error);
      showToast('Failed to fetch communication stats.');
    }
  };

  const fetchApplicationStates = async (offerId) => {
    try {
      const response = await sendHttpCall('GET', `jobs/get_applications_count?offerId=${offerId}`)

      console.log('Response received:', response);

      if (response) {
        console.log(response);
        setApplicationState(response)
        setViewApplicationStates(true)
        console.log('Data Received: ', applicationState);
      }
    }
    catch (error) {
      console.error('Error fetching application states:', error);
      showToast('Failed to fetch communication states');
    }
  }

  const confirmStatusChange = () => {
    const newStatus = isShortlisting ? 1 : 0;
    manualShortlister(data.offerId, currentCandidate.user_id, newStatus)
      .then(() => {
        showToast(`Status changed successfully for ${currentCandidate.name}.`);
        setShowConfirmation(false);
        setTimeout(refreshData, 1200);
        setViewCandidates(false);
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        showToast('Failed to change status.');
        setShowConfirmation(false);
      });
  };

  const StatsComponent = ({
    viewStats,
    setViewStats,
    whatsappStats,
    emailStats
  }) => {
    // Prepare data for WhatsApp display
    console.log(whatsappStats);
    console.log(emailStats);
    const whatsappDisplayData = Object.entries(whatsappStats).map(
      ([status, count]) => ({
        label:
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' '),
        count
      })
    );

    // Prepare data for Email display
    const emailDisplayData = Object.entries(emailStats).map(
      ([status, count]) => ({
        label:
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' '),
        count
      })
    );

    return (
      viewStats && (
        <ReactModal
          isOpen={viewStats}
          onRequestClose={() => setViewStats(false)}
          className="active_jobs__modal-modal2"
          overlayClassName="active_jobs__modal-overlay2">
          <div className="active_jobs__modal2">
            <h3 style={{ fontWeight: 'bold' }}>WhatsApp Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                {whatsappDisplayData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count} />
                  </Grid>
                ))}
              </Grid>
            </div>
            <hr style={{ margin: '40px 0' }} />{' '}
            {/* Separator between sections */}
            <h3 style={{ fontWeight: 'bold' }}>Email Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid container spacing={3} style={{ paddingTop: '20px' }}>
                {emailDisplayData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </ReactModal>
      )
    );
  };

  const ApplicationStatesComponent = ({
    viewApplicationStates,
    setViewApplicationStates,
    applicationState
  }) => {
    console.log("Application Data: ", applicationState);

    //preparing data for application states
    const applicationDisplayData = Object.entries(applicationState).map(
      ([status, count]) => ({
        label: convertCamelCaseToReadable(
          status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')),
        count
      })
    );

    return (
      viewApplicationStates && (
        <ReactModal
          isOpen={viewApplicationStates}
          onRequestClose={() => setViewApplicationStates(false)}
          className="active_jobs__modal-modal2"
          overlayClassName="active_jobs__modal-overlay2">
          <div className="active_jobs__modal2">
            <h3 style={{ fontWeight: 'bold' }}>Application Stats</h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around'
              }}>
              <Grid container spacing={3} style={{ paddingTop: '30px',
              paddingBottom:'30px'
               }}>
                {applicationDisplayData.map((data) => (
                  <Grid item lg={2} sm={6} xl={3} xs={12} key={data.label}>
                    <TotalUsers Text={data.label} totalUser={data.count} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </ReactModal>
      )
    );
  };

  const toggleDescriptionExpand = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const openUserPage = (userId) => {
    window.open(`/dashboard#/account/${userId}`, '_blank');
  };

  function DropdownMenu({ data, showToast }) {
    const handleCopyOfferId = () => {
      navigator.clipboard.writeText(data.offerId).then(
        () => showToast('Offer ID copied to clipboard!'),
        (err) => console.error('Could not copy text: ', err)
      );
    };

    const handleCopyInterviewLink = () => {
      if (checked) {
        navigator.clipboard.writeText(data.interviewLink).then(
          () => showToast('Interview link copied to clipboard!'),
          (err) => console.error('Could not copy text: ', err)
        );
      } else {
        showToast('Auto-Recruiter not enabled');
      }
    };

    const handleJobPagePreview = () => {
      window.open(data.url, '_blank');
    };

    const isLinkDisabled = !checked;

    return (
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="22"
            style={{ transform: 'rotate(90deg)' }}
            fill="currentColor"
            class="bi bi-three-dots"
            viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
          </svg>
        </button>
        <div className={styles.dropdownContent}>
          <a onClick={handleCopyOfferId}>
            <FaClipboard className={styles.icon} /> Copy Offer ID
          </a>
          <a
            onClick={handleCopyInterviewLink}
            className={isLinkDisabled ? styles.dropdownLinkDisabled : ''}>
            <FaLink className={styles.icon} /> Interview Link
          </a>
          <a onClick={handleJobPagePreview}>
            <FaEye className={styles.icon} /> JobPage Preview
          </a>
        </div>
      </div>
    );
  }

  const description = data.description;
  const shouldShorten = description.length > 300;
  const displayedDescription =
    shouldShorten && !isDescriptionExpanded
      ? `${description.substring(0, 300)}...`
      : description;

  // console.log("checkkk")
  console.log(data);
  // console.log(data.potentialuser8Email.data)
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {
            status === 'INACTIVE' && (
              // data.admin_approval_status === 'REJECTED' ? (
              <div className={styles.rejectReason}>
                {data.admin_approval_status === 'REJECTED' ? (
                  <strong>Rejected:</strong>
                ) : data.admin_approval_status === 'APPROVED' ? (
                  <strong style={{ color: '#000' }}>Closed:</strong>
                ) : (
                  <strong style={{ color: '#000' }}>Closed:</strong>
                )}{' '}
                {data.admin_message}
              </div>
            )
            // ) : (
            //   ''
          }
          <div className={styles.details}>
            {/* <span /> */}
            <div className={styles.type}>{data.opportunity}</div>
            <div className={styles.time}>
              {status === 'ACTIVE'
                ? 'Approved'
                : status === 'INACTIVE'
                  ? data.admin_approval_status === 'REJECTED'
                    ? 'Rejected'
                    : data.admin_approval_status === 'APPROVED'
                      ? 'Closed'
                      : 'Closed'
                  : 'Posted'}{' '}
              at - {formatTime(new Date(data.date))}
              {(data.adminName && data.adminName !== 'undefined') ||
                data.email ? (
                <>
                  <br />
                  <span>
                    by-
                    {data.adminName && data.adminName !== 'undefined'
                      ? data.adminName
                      : data.email}
                  </span>
                </>
              ) : null}
            </div>
          </div>
          <div className={styles.hirerDetails}>
            <div className={styles.hirerDetailsLeft}>
              <div className={styles.hirerImg}>
                <img src={data.userInfo[0].image_id} />
              </div>
              <div className={styles.hirerTextDetails}>
                <Link
                  // onClick={() => {
                  //   sessionStorage.setItem('viewJobPage', currentPage);
                  // }}
                  className={styles.hirerName}
                  to={`/account/${data.userInfo[0].user_id}`}>
                  {data.userInfo[0].name}
                </Link>

                <div className={styles.hirerCompany}>
                  {data.userInfo[0].organisation}
                </div>
                {data.invite_source && data.invite_source !== '' && (
                  <div className={styles.type2}>
                    {data.invite_source.split('_').slice(1).join('_')}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.rightBtns}>
              <div className={styles.autoBtn}>
                {status === 'ACTIVE' && (
                  <CheckBox
                    checked={checked}
                    onClick={() => {
                      toggleAutoRecruiter(data.offerId, !checked).then(
                        (res) => {
                          setChecked((a) => !a);
                        }
                      );
                    }}
                  />
                )}
              </div>
              <div className={styles.dropBtn}>
                <DropdownMenu data={data} showToast={showToast} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.row}>
            <div>
              <div className={styles.title}>{data.title}</div>
              <div className={styles.subtitle}>
                {data.jobType.length > 0
                  ? data.jobType[0].job_type
                  : data.type_of_service || data.entrepreneur_work_type}{' '}
                - {data.location_city || 'Remote'}
              </div>
            </div>
            <div className={styles.hirerJobCount}>{data?.jobCount}</div>
          </div>
          {data.preferredLocations && data.preferredLocations.length > 0 && (
            <div className={styles.section}>
              <div className={styles.sectionTitle}>Preferred Locations</div>
              <div className={styles.sectionContent2}>
                <div className={styles.sectionCards}>
                  {data.preferredLocations.map((item) => (
                    <div className={styles.sectionCard}>{item.location}</div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {data.industry_type && (
            <>
              <div className={styles.sectionTitle}>Industry</div>
              <div className={styles.type3}>{data.industry_type}</div>
            </>
          )}
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Description</div>
            <div className={styles.sectionContent}>{description}</div>

            {/* {shouldShorten && (
              <button onClick={toggleDescriptionExpand} className={styles.sectionCard} style={{ backgroundColor: '#1A237E' }}>
                {isDescriptionExpanded ? 'Show less' : 'Read more'}
              </button>
            )} */}
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Skills</div>
            <div className={styles.sectionContent2}>
              <div className={styles.sectionCards}>
                {data.skills
                  .sort((a, b) => a.is_soft_skill - b.is_soft_skill)

                  .map((skill) => (
                    <div
                      className={
                        styles.sectionCard +
                        ' ' +
                        (skill.is_soft_skill ? styles.sectionCardVariant2 : '')
                      }>
                      {skill.skill}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.sectionTitle}>Offer Requirements:</div>
          </div>

          <div className={styles.candidateTable}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>
                {' '}
                {data.monthly_compensation_id
                  ? 'Yearly Compensation'
                  : data.hourly_compensation_id
                    ? 'Hourly Compensation'
                    : 'Earning Potential'}
              </div>
              <div className={styles.valueCell2}>
                {data.displayCompensation}
              </div>
            </div>

            {data.entrepreneur_investment_potential_id && (
              <div className={styles.tableRow}>
                <div className={styles.tableCell}>Investment Potential </div>
                <div className={styles.valueCell2}>
                  {data.entrepreneur_investment_potential}
                </div>
              </div>
            )}
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>Highest Qualification</div>
              <div className={styles.valueCell2}>
                {data.highest_qualification_required}
              </div>
            </div>

            <div className={styles.tableRow}>
              <div className={styles.tableCell}>Work Experience </div>
              <div className={styles.valueCell2}>
                {`${data.lowerworkex} - ${data.upperworkex} years`}
              </div>
            </div>
          </div>

          {/* POTENTIAL DATA */}
          <div className={styles.section}>
            <div className={styles.sectionTitle}>Potential Candidates:</div>
          </div>

          <div className={styles.candidateTable}>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}> S3</div>

              {data.potentialCandidateAiCutOffCount > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_ai_cutoff/${data.offerId}`}>
                    {data.potentialCandidateAiCutOffCount}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>

            <div className={styles.tableRow}>
              <div className={styles.tableCell}>S8 (Mobile)</div>

              {data.potentialuserss8m > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    onClick={() => {
                      sessionStorage.setItem('viewJobPage', currentPage);
                    }}
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_s8m/${data.offerId}`}>
                    {data.potentialuserss8m}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>
            <div className={styles.tableRow}>
              <div className={styles.tableCell}>S8 (Email)</div>

              {data.potentialuserss8e > 0 ? (
                <div className={styles.valueCell}>
                  <Link
                    onClick={() => {
                      sessionStorage.setItem('viewJobPage', currentPage);
                    }}
                    style={{ color: '#222' }}
                    to={`/job/potential_candidates_s8e/${data.offerId}`}>
                    {data.potentialuserss8e}
                  </Link>
                </div>
              ) : (
                <div className={styles.na}>NA</div>
              )}
            </div>
          </div>

          {status !== 'UNDER_REVIEW' && (
            <>
              <div
                className={styles.section2}
                style={data.applicationsCount ? { cursor: 'pointer' } : null}
                onClick={() =>
                  data.applicationsCount
                    ? fetchApplications(data.offerId)
                    : null
                }>
                <div className={styles.sectionTitle}>No. of Applications</div>
                <div className={styles.sectionContent3}>
                  <div className={styles.applicationsCount}>
                    <span>
                      {' '}
                      {data.applicationsCount ||
                        data.applicationCount ||
                        0}{' '}
                    </span>
                    {data.applicationsCount > 0 && (
                      <div className={styles.viewApplicationsLength}>
                        (View)
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.section2}>
                <div className={styles.sectionTitle}>Communications Stats</div>
                <div className={styles.buttons}>
                  <button
                    className={styles.primaryButton}
                    onClick={() => fetchStats(data.offerId)}>
                    Fetch Stats
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        {!hideButtons && (
          <div className={styles.footer}>
            <div className={styles.buttons}>
              {status !== 'INACTIVE' && (
                <>
                  {' '}
                  <button
                    className={styles.dangerButton}
                    onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </button>
                  <button
                    className={styles.primaryButtonAlt}
                    onClick={() => setEditJob(true)}>
                    Edit
                  </button>
                  <button
                    className={styles.dangerButtonAlt}
                    onClick={() => setShowRejectJobModal(true)}>
                    Reject
                  </button>
                </>
              )}
              {status === 'ACTIVE' && userType === 'ADMIN' && (
                <button
                  className={styles.primaryButton}
                  onClick={() => openBoostDrawer(data.offerId)}>
                  Boost
                </button>
              )}
              {(status === 'UNDER_REVIEW' || status === 'ON_HOLD') && (
                <div className={styles.dropdown}>
                  <div
                    className={`${styles.dropdownContent} ${styles.approveDropdownContent}`}>
                    <a onClick={() => approveJob(data.offerId, true)}>
                      <FaGift className={styles.icon} /> Free Job
                      {/*   isFreeActive : true */}
                    </a>
                    <a onClick={() => approveJob(data.offerId)}>
                      <FaMoneyBillAlt className={styles.icon} /> Paid Job
                    </a>
                  </div>
                  <button className={styles.primaryButton}>Approve</button>
                </div>
                // <button
                //   className={styles.primaryButton}
                //   onClick={() => approveJob(data.offerId)}>
                //   Approve
                // </button>
              )}
              {status === 'UNDER_REVIEW' && (
                <button
                  className={styles.onHoldButton}
                  onClick={() => setJobOnHold(data.offerId)}>
                  Hold
                </button>
              )}

              {/* {status === 'ACTIVE' && (
              <button
                className={styles.primaryButton}
                // onClick={() => approveJob(data.offerId)}>
              >
                Boost Job
              </button>
            )} */}
            </div>
          </div>
        )}
      </div>
      {viewCandidates && (
        <ReactModal
          isOpen={viewCandidates}
          onRequestClose={() => {
            setViewCandidates(false);
            setSearchTerm('');
          }}
          className="active_jobs__modal-modal2"
          overlayClassName="active_jobs__modal-overlay2">
          <div className="active_jobs__modal2">
            <div style={{
              display: 'flex',
              padding: '10px 20px',
              justifyContent: 'space-between',
            }}>
              <div className="candidate_list_title">Candidates</div>
              <button className="candidate_application_count_btn"
                onClick={() => fetchApplicationStates(data.offerId)}>View Stats</button>

            </div>

            <div className="candidate_list_title">
              <TextField
                label="Search by Candidate Name or User ID"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleSearchSubmit}
              />
            </div>

            {/* Loader and conditionally rendering content */}
            {applicationsLoading ? (
              <div className="loader-container">
                <Loader />{' '}
                {/* Make sure this Loader component is properly imported and styled */}
              </div>
            ) : applications.length > 0 ? (
              <div className="candidate_list_items">
                {applications.map((a, index) => (
                  <div className="candidate_list_item" key={a.user_id}>
                    <div className="candidate_list_item_no">
                      {(page - 1) * 10 + index + 1}
                    </div>
                    <div>
                      <img
                        src={a.image_id}
                        className="candidate_list_item_img"
                        alt=""
                      />
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => openUserPage(a.user_id)}
                        className="candidate_list_item_name">
                        {a.name}{' '}
                        {a.is_good_fit ? (
                          <span className="candidate_list_item_name_r">R</span>
                        ) : (
                          ''
                        )}
                        {a.interviewId ? (
                          <Link
                            to={`/auto-interview/interview/${a.interviewId}`}
                            className="candidate_list_item_name_r"
                            style={{
                              backgroundColor: a.interviewCompleted
                                ? '#297bca'
                                : '#95cfff',
                              cursor: 'pointer'
                            }}>
                            i
                          </Link>
                        ) : (
                          ''
                        )}
                        {a.profile_viewed ? (
                          <div className={styles.tooltipIconContainer}>
                            <FaEye />
                            <span className={styles.tooltipText}>
                              Profile Viewed
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div>
                      {a.apply_device === 'web' && <FaLaptop />}
                      {a.apply_device === 'ios' && <FaApple />}
                      {a.apply_device === 'android' && <FaAndroid />}
                    </div>
                    <div
                      className={
                        'candidate_list_item_status' +
                        (a.request_status === 'ACCEPTED'
                          ? ' status_blue'
                          : '') +
                        (a.request_status === 'REJECTED' ? ' status_red' : '')
                      }>
                      {a.request_status === 'ACCEPTED'
                        ? 'SHORTLISTED'
                        : a.request_status}
                    </div>

                    <button
                      onClick={() => handleToggleStatus(a)}
                      style={{
                        marginLeft: '8px',
                        cursor: 'pointer',
                        border: 'none',
                        backgroundColor: 'transparent',
                        color:
                          a.request_status === 'ACCEPTED'
                            ? '#ff4e4e'
                            : '#297bca',
                        fontSize: '24px'
                      }}>
                      <FaGgCircle />
                    </button>

                    <div
                      className={`candidate_list_item_applyStatus ${a.apply_source === 'WHATSAPP'
                        ? 'whatsapp'
                        : a.apply_source === 'FEED'
                          ? 'feed'
                          : a.apply_source === 'LINKEDIN'
                            ? 'linkedin'
                            : a.apply_source === 'SEARCH'
                              ? 'search'
                              : ''
                        }`}>
                      {a.apply_source}
                    </div>

                    <button
                      className={styles.feedbackBtn}
                      onClick={() => {
                        setCurrentFeedback(a.shortlisterFeedBack);
                        setCurrentPrompt(a.prompt);
                        setShowFeedbackModal(true);
                        setApplicationStatus(a.request_status);
                      }}>
                      AI
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div>No applications found</div>
            )}

            <div className="pagination">
              <div className="active_jobs__pagination-count">
                Total Count: {totalCount}
              </div>
              <Pagination
                size="large"
                color="#297bca"
                count={lastPage}
                page={page}
                onChange={handleChange}
              />
              <Box display="flex" alignItems="center" gap={1}>
                <TextField
                  size="small"
                  label="Go to page"
                  variant="outlined"
                  value={goToPage}
                  onChange={handleGoToPageChange}
                  inputProps={{ type: 'number', min: 1, max: lastPage }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleGoToPage();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#297bca' }}
                  onClick={handleGoToPage}>
                  Go
                </Button>
              </Box>
            </div>
          </div>
          <ToggleStatusModal
            isOpen={showConfirmation}
            onRequestClose={() => setShowConfirmation(false)}
            confirmAction={confirmStatusChange}
            candidateName={currentCandidate.name}
            isShortlisting={isShortlisting}
          />

          <FeedbackModal
            isOpen={showFeedbackModal}
            onRequestClose={() => setShowFeedbackModal(false)}
            feedback={currentFeedback}
            prompt={currentPrompt}
            status={applicationStatus}
          />

        </ReactModal>
      )}

      <StatsComponent
        viewStats={viewStats}
        setViewStats={setViewStats}
        whatsappStats={whatsappStats}
        emailStats={emailStats}
      />

      <ApplicationStatesComponent
        viewApplicationStates={viewApplicationStates}
        setViewApplicationStates={setViewApplicationStates}
        applicationState={applicationState}
      />
      {editJob && (
        <ReactModal
          isOpen={editJob}
          className="active_jobs__modal-modal"
          overlayClassName="active_jobs__modal-overlay">
          <EditJobPostV2
            cancel={() => setEditJob(false)}
            dataProps={data}
            id={data?.offerId}
            showToast={showToast}
            refresh={() => {
              setEditJob(false);
              setTimeout(refreshData, 500);
            }}
          />
        </ReactModal>
      )}
      {showRejectJobModal && (
        <ReactModal
          isOpen={showRejectJobModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Select a reason to reject this job for {data.title} by{' '}
              {data.userInfo && data.userInfo[0].name}?
            </div>
            {showRejectJobModal && (
              <ReactSelect
                value={rejectReason}
                onChange={(e) => {
                  setRejectReasonCustom('');
                  setRejectReason(e);
                }}
                options={[
                  {
                    label: 'Insufficient information to Apply',
                    value: 'Insufficient information to Apply'
                  },
                  {
                    label: 'Role Description not appropriate',
                    value: 'Role Description not appropriate'
                  },
                  {
                    label: 'Please add more skills',
                    value: 'Please add more skills'
                  },
                  {
                    label:
                      'Salary does not commensurate with skills / experience',
                    value:
                      'Salary does not commensurate with skills / experience'
                  },
                  {
                    label: 'Post not Appropriate',
                    value: 'Post not Appropriate'
                  },
                  {
                    label: 'Official Email not there',
                    value: 'Official Email not there'
                  },
                  {
                    label: 'Official Website not there',
                    value: 'Official Website not there'
                  },
                  {
                    label: 'Official Email & Website not there',
                    value: 'Official Email & Website not there'
                  },
                  { value: 'Custom message', label: 'Custom message' }
                ]}
              />
            )}
            {rejectReason?.value === 'Custom message' && (
              <input
                className={styles.textField}
                placeholder="Choose custom message"
                type="text"
                value={rejectReasonCustom}
                onChange={(e) => setRejectReasonCustom(e.target.value)}
              />
            )}
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  onClick={() => {
                    rejectJob(
                      data.offerId,
                      rejectReasonCustom || rejectReason?.value
                    );
                  }}>
                  Reject
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowRejectJobModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
      {showBoostJobModal && (
        <ReactModal
          isOpen={showBoostJobModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Are you sure you want to boost this job for {data.title}?
            </div>
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  style={{
                    backgroundColor: '#3485ff'
                  }}
                  onClick={() => {
                    boostJob(data.offerId);
                    setShowBoostJobModal(false);
                  }}>
                  Boost Job
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowBoostJobModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
      {showDeleteModal && (
        <ReactModal
          isOpen={showDeleteModal}
          className="jobs_under_review__modal-modal"
          overlayClassName="jobs_under_review__modal-overlay">
          <div className="jobs_under_review__modal">
            <div className={styles.title2}>
              Do you really want to delete this job from database. This action
              is irreversible.{' '}
            </div>
            <div className={styles.buttons}>
              <div className="jobs_under_review__modal-btns">
                <button
                  className="jobs_under_review__modal-btn-reject"
                  onClick={() => {
                    deleteJob(data.offerId);
                  }}>
                  DELETE
                </button>
                <button
                  className="jobs_under_review__modal-btn-cancel"
                  onClick={() => {
                    setShowDeleteModal(false);
                  }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      )}
    </>
  );
}

function CheckBox({ checked, onClick }) {
  return (
    <>
      {' '}
      <input checked={checked} type="checkbox" className={styles.switchInput} />
      <label className={styles.switchLabel} onClick={onClick}>
        <div class={styles.switchContainer}>
          {/* <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} />
          <div class={styles.switchItem} /> */}
        </div>
      </label>
    </>
  );
}

//function to convert camelcase string into human readable form
function convertCamelCaseToReadable(str) {
  let result = str.replace(/([A-Z])/g, ' $1').toLowerCase();
  result = result
  .split(' ')                    // Split the string by spaces
  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
  .join(' ');  
  return result;
}