import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactModal from 'react-modal';
import MaterialTable, { MTablePagination } from 'material-table';
import ReactSelect from 'react-select';
import styles from './Social.module.scss';
import { makeStyles } from '@material-ui/styles';
import './styles.css';
import {
  Paper,
  // Card,
  // CardContent,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
  Grid,
  // TextField,
  Button
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
// import EmailIcon from '@material-ui/icons/Email';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import PinDropIcon from '@material-ui/icons/PinDrop';
// import WcIcon from '@material-ui/icons/Wc';
// import Crop32Icon from '@material-ui/icons/Crop32';
// import Rating from '@material-ui/lab/Rating';
// import EditIcon from '@material-ui/icons/Edit';
// import { apiUrl } from 'config';
// import FormDialog from 'components/CustomDialog/CustomDialog';
import { sendHttpCall } from 'shareService/httpService';
// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1)
  },
  details: {
    display: 'flex'
  },
  avatar: {
    height: 120,
    width: 120,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    margin: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  score: {
    // margin: theme.spacing(3),
    border: '2px solid #3f51b5',
    borderRadius: 2,
    cursor: 'pointer',
    marginLeft: 10
  }
}));

const UserAccountDetails = (props) => {
  const {
    className,
    userid,
    user,
    modes,
    selectedMode,
    setSelectedMode,
    dataFromResume,
    ogprops,
    rcoinHistory,
    refreshData,
    ...rest
  } = props;
  console.log(user);
  const [showAddGcoins, setShowAddGcoins] = useState(false);
  const [gcoinsToAdd, setGcoinsToAdd] = useState(0);
  const classes = useStyles();
  const [purposeVal, setPurposeVal] = useState(null);
  const [parameters, setParameters] = useState([]);
  // const [user, setUser] = useState({});
  const [open, setopen] = useState(false);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [confirmOfferBlock, setConfirmOfferBlock] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [purpose, setPurpose] = useState(' ');
  const [showPurposeModal, setShowPurposeModal] = useState(false);
  const [userStatus, setUserStatus] = useState(user.status);
  // const history=useHistory();
  // const users = props.user;

  // async function fetchUserDetail() {
  //   sendHttpCall('GET', `users/user_detail/${props.userid}`, null, props).then((response) => {
  //     setUser(response);
  //   })
  // }

  const rcStyle = {
    color: '#00b8e6',
    fontWeight: 'bold'
  };

  async function fetchUserParamerViewedScore() {
    sendHttpCall(
      'GET',
      `users/user_parameter/${props.userid}`,
      null,
      props
    ).then((response) => {
      setParameters(response);
    });
  }

  const handleBlock = async () => {
    console.log(user);
    let status1 = user.status == 5 ? 3 : 5;
    sendHttpCall(
      'POST',
      'users/block',
      { userId: user.user_id, status: status1 },
      props
    )
      .then((response) => {
        setUserStatus(status1);
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleOfferBlock = async () => {
    let disable = user.sendOfferDisabled ? false : true;
    sendHttpCall(
      'POST',
      'users/toggle_send_offers',
      { userId: user.user_id, disable },
      props
    )
      .then((response) => {
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleDeactivate = async () => {
    let status1 = user.status == 6 ? 3 : 6;
    sendHttpCall(
      'POST',
      'users/deactivate',
      { userId: user.user_id, status: status1 },
      props
    )
      .then((response) => {
        setUserStatus(status1);
        window.location.reload(true);
      })
      .catch((e) => {
        alert('Something went wrong');
      });
  };
  const handleOpenDialog = () => {
    setopen(true);
  };

  const getDialogData = (data) => {
    if (data == 'x') {
      setopen(false);
    }
  };

  const fixedLenthString = (data) => {
    if (data.length > 0) {
      var d = data.substring(0, data.length / 3);
      return d;
    }
  };

  useEffect(() => {
    // fetchUserDetail();
    fetchUserParamerViewedScore();
  }, []);
  const addGcoinsForHirer = () => {
    sendHttpCall(
      'POST',
      'users/add_rcoins',
      { userId: user.user_id, amount: gcoinsToAdd },
      props
    )
      .then((response) => {
        window.location.reload(true);
        refreshData();
      })
      .catch((e) => {
        console.log(e);
        alert('Something went wrong');
      });
  };
  const changePurpose = () => {
    sendHttpCall(
      'POST',
      'users/changePurpose',
      { userId: user.user_id, purposeId: purposeVal.value },
      props
    )
      .then((response) => {
        window.location.reload(true);
      })
      .catch((e) => {
        console.log(e);
        alert('Something went wrong');
      });
  };
  const planNames = {
    HIRER_ADD_ON_1: 'Bronze',
    HIRER_ADD_ON_2: 'Silver',
    HIRER_ADD_ON_3: 'Gold',
    HIRER_PREMIUM: 'Enterprise',
    HIRER_ADD_ON_4: 'Basic',
    HIRER_ADD_ON_5: 'Premium',
    HIRER_ADD_ON_6: 'Enterprise',
    HIRER_ADD_ON_7: 'Starter',
    HIRER_ADD_ON_8: 'Basic',
    HIRER_ADD_ON_9: 'Premium',
    HIRER_ADD_ON_10: 'Enterprise',

    HIRER_ADD_ON_10: 'Enterprise',

    HIRER_JOB_PLAN_1: 'Jobs Basic',

    HIRER_JOB_PLAN_2: 'Jobs Standard',

    HIRER_JOB_PLAN_3: 'Jobs Gold',

    HIRER_OFFER_PLAN_1: 'Offers Basic',

    HIRER_OFFER_PLAN_2: 'Offers Standard',

    HIRER_OFFER_PLAN_3: 'Offers Gold',

    HIRER_CV_PLAN_1: 'CV Basic',

    HIRER_CV_PLAN_2: 'CV Standard',

    HIRER_CV_PLAN_3: 'CV Gold'
  };
  const convertToFeet = (n) => {
    const realFeet = (n * 0.3937) / 12;
    const feet = Math.floor(realFeet);
    const inches = Math.round((realFeet - feet) * 12);
    if (inches === 12) return `${feet + 1}' 0"`;
    return `${feet}' ${inches}"`;
  };
  useEffect(() => {
    if (user.purpose) {
      if (user.purpose[0] === 'Hiring') {
        setPurposeVal({ label: 'Hiring', value: 18 });
      } else if (user.purpose[0] === 'Full-Time Work') {
        setPurposeVal({ label: 'Full-Time Work', value: 27 });
      }
    }
  }, [user]);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item>
            <button
              className={
                'modeBtn' +
                (modes.hiring ? ' modeBtnActive' : '') +
                (selectedMode === 'HIRING' ? ' modeBtnEnabled' : '')
              }
              onClick={() =>
                modes.hiring || true ? setSelectedMode('HIRING') : null
              }>
              RECRUITER
            </button>
            <button
              className={
                'modeBtn' +
                (modes.member ? ' modeBtnActive' : '') +
                (selectedMode === 'MEMBER' ? ' modeBtnEnabled' : '')
              }
              onClick={() =>
                modes.member || true ? setSelectedMode('MEMBER') : null
              }>
              MEMBER
            </button>
            <button
              className={
                'modeBtn' +
                (modes.social ? ' modeBtnActive' : '') +
                (selectedMode === 'SOCIAL' ? ' modeBtnEnabled' : '')
              }
              onClick={() =>
                modes.social || true ? setSelectedMode('SOCIAL') : null
              }>
              SOCIAL
            </button>
          </Grid>
        </Grid>
        <Grid container>
          {selectedMode === 'SOCIAL' ? (
            <div className={styles.container}>
              <div className={styles.userDetails}>
                <div className={styles.userPfp}>
                  <img src={user?.socialProfile?.images[0]?.link} alt="" />
                </div>
                <div>
                  <div className={styles.userName}>
                    {user?.socialProfile?.name}
                  </div>
                  <div className={styles.userJoinedOn}>
                    {user.status === 5
                      ? 'Blocked'
                      : user.status === 6
                      ? 'Account Deleted'
                      : user?.socialProfile?.joined_on
                      ? 'Joined ' + user?.socialProfile?.joined_on
                      : 'Social Onboarding not completed'}
                  </div>
                </div>
              </div>
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Images</div>
                <div className={styles.userImagesContainer}>
                  {user?.socialProfile?.images.map((item) => (
                    <div className={styles.userImg}>
                      <img src={item.link} alt="" srcset="" />
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Looking For</div>
                <div className={styles.sectionValue}>
                  {user?.socialProfile?.lookingFor
                    ?.map((a) => a.value)
                    .join(', ')}
                </div>
              </div>{' '}
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Interests</div>
                <div className={styles.sectionValue}>
                  {user?.socialProfile?.interests
                    ?.map((a) => a.value)
                    .join(', ')}
                </div>
              </div>{' '}
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Bio</div>
                <div className={styles.sectionValue}>
                  {user?.socialProfile?.bio}
                </div>
              </div>
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Family Details</div>

                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Father Name :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.familyDetails?.fatherName}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Father Profession :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {
                      user?.socialProfile?.familyDetails?.fatherProfession
                        ?.value
                    }
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Mother Name :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.familyDetails?.motherName}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Mother Profession :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {
                      user?.socialProfile?.familyDetails?.motherProfession
                        ?.value
                    }
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    No of Siblings :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.familyDetails?.noOfSiblings}
                  </span>
                </div>
              </div>{' '}
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Other Details</div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Age :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.age}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Gender :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.gender}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Location :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.location}
                  </span>
                </div>
                {user.locationDetails && (
                  <div className={styles.sectionItem}>
                    <span className={styles.sectionItemLabel}>
                      <b> Sublocality :</b>
                    </span>
                    <span className={styles.sectionItemValue}>
                      {user.locationDetails?.sub_locality}
                    </span>{' '}
                  </div>
                )}
                {user.locationDetails && (
                  <div className={styles.sectionItem}>
                    <span className={styles.sectionItemLabel}>
                      <b> Complete Address :</b>
                    </span>
                    <span className={styles.sectionItemValue}>
                      {user.locationDetails?.complete_address}
                    </span>{' '}
                  </div>
                )}
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Height :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.height
                      ? convertToFeet(user?.socialProfile?.height)
                      : ''}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>Weight :</span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.weight
                      ? user?.socialProfile?.weight + ' kg'
                      : ''}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Interested in :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.interestedIn}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Relationship Status :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.relationshipStatus}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Connects Sent :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.connectsSent}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Connects Sent And Accepted :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {user?.socialProfile?.connectsSentAndAccepted}
                  </span>
                </div>
                <div className={styles.sectionItem}>
                  <span className={styles.sectionItemLabel}>
                    Last Session :
                  </span>
                  <span className={styles.sectionItemValue}>
                    {moment(user.lastSession + ' UTC').fromNow()}
                  </span>
                </div>
                {!modes.member ? (
                  <>
                    <div className={styles.sectionItem}>
                      <span className={styles.sectionItemLabel}>
                        Device Type :
                      </span>
                      <span className={styles.sectionItemValue}>
                        {user?.device_type}
                      </span>
                    </div>
                    <div className={styles.sectionItem}>
                      <span className={styles.sectionItemLabel}>Source :</span>
                      <span
                        className={styles.sectionItemValue}
                        onClick={() => {
                          if (user.source_user_id)
                            ogprops.history.push(
                              `/account/${user.source_user_id}`
                            );
                        }}
                        style={
                          user?.source_user_id ? { cursor: 'pointer' } : null
                        }>
                        {user?.source}
                      </span>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item>
                <Avatar className={classes.avatar} src={user.image_id} />
              </Grid>
              <Grid item>
                <div style={{ display: 'flex' }}>
                  <Typography gutterBottom variant="h2">
                    {user.name}{' '}
                    <span style={{ fontSize: '.7em', fontWeight: 500 }}>
                      {selectedMode === 'HIRING'
                        ? user?.hirerPlanName
                          ? '(' +
                              planNames[user.hirerPlanName.split('~I~')[1]] ||
                            user.hirerPlanName.split('~I~')[1] + ' Plan User)'
                          : ''
                        : ''}
                    </span>
                  </Typography>
                  <Button style={{backgroundColor: '#8484ff',color:'white',marginLeft:'20px', fontSize:'10px',borderRadius:'40px'}} onClick={()=>{ogprops.history.push(
                              '/onboarding-chat/' + user.user_id 
                            );}}>show chats</Button>
                </div>
                <Typography gutterBottom variant="h6" color="textSecondary">
                  <span style={{ color: '#363130' }}>
                    <b> User Id:</b>
                  </span>{' '}
                  {user.user_id}
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Phone Number:</b>
                  </span>{' '}
                  <a
                    href={`https://wa.me/${
                      user.country_code?.replace('+', '') + user.mobile_number
                    }`}
                    style={{ color: '#546e7a' }}>
                    {user.country_code + user.mobile_number}
                  </a>
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Email ID:</b>
                  </span>{' '}
                  <a
                    href={`mailto:${user.email_id}`}
                    style={{ color: '#546e7a' }}>
                    {user.email_id}
                  </a>
                </Typography>
                {selectedMode === 'MEMBER' && (
                  <Typography
                  gutterBottom
                  color="textSecondary"
                  variant="h6"
                  onClick={() => {
                    if (user.jobAppliedCount > 0) {
                      const currentDate = new Date().toISOString().slice(0, 10);
                      ogprops.history.push(`/data/jobsApplied/Today/2019-01-01/${currentDate}?userId=${user.user_id}`);
                    }
                  }}
                  style={{ cursor: 'pointer' , color: '#297bca'}} 
                >
                  <span style={{ color: '#363130' }}>
                    <b> Jobs Applied:</b>
                  </span>{' '}
                  {user.jobAppliedCount}
                </Typography>
                
                )}
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Status:</b>
                  </span>{' '}
                  {user.status}
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Industry:</b>
                  </span>{' '}
                  {user.industry_type}
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Device Type:</b>
                  </span>{' '}
                  {user.device_type}
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> IP Address:</b>
                  </span>{' '}
                  {user.ipDetails?.ip}
                </Typography>
                <Typography gutterBottom color="textSecondary" variant="h6">
                  <span style={{ color: '#363130' }}>
                    <b> Location from IP:</b>
                  </span>{' '}
                  {user.ipDetails?.location_from_ip}
                </Typography>
                <Typography gutterBottom variant="h6" color="textSecondary">
                  <span style={{ color: '#363130' }}>
                    <b> City :</b>
                  </span>{' '}
                  {user.location_city}
                </Typography>
                {user.locationDetails && (
                  <Typography gutterBottom variant="h6" color="textSecondary">
                    <span style={{ color: '#363130' }}>
                      <b> Sublocality :</b>
                    </span>{' '}
                    {user.locationDetails?.sub_locality}
                  </Typography>
                )}
                {user.locationDetails && (
                  <Typography gutterBottom variant="h6" color="textSecondary">
                    <span style={{ color: '#363130' }}>
                      <b> Complete Address :</b>
                    </span>{' '}
                    {user.locationDetails?.complete_address}
                  </Typography>
                )}

                {(user.status === 2 || selectedMode !== 'MEMBER') && (
                  <>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b>Eligible to send offers :</b>
                      </span>{' '}
                      {user.user_id && (
                        <span
                          onClick={() => setConfirmOfferBlock(true)}
                          style={{
                            cursor: 'pointer',
                            color: user.sendOfferDisabled
                              ? '#ff1940'
                              : '#3363ff'
                          }}>
                          <b> {!user.sendOfferDisabled ? 'YES' : 'NO'}</b>
                        </span>
                      )}
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div style={{ display: 'flex', cursor: 'pointer' }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Plan Purchased On:</b>
                        </span>{' '}
                        {user?.hirerPlanName
                          ? new Date(
                              user.hirerPlanName.split('~I~')[0] + ' UTC'
                            ).toLocaleString()
                          : ''}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div style={{ display: 'flex', cursor: 'pointer' }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>R-Coins :</b>
                        </span>{' '}
                        {user.rcoins}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                          if (parseInt(user.jobsPosted, 10) > 0)
                            ogprops.history.push(
                              '/jobs-by-user/' + user.user_id + '/ALL'
                            );
                          window.location.reload();
                        }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Jobs Posted :</b>
                        </span>{' '}
                        {user.jobsPosted || 0}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                          if (parseInt(user.jobsActive, 10) > 0)
                            ogprops.history.push(
                              '/jobs-by-user/' + user.user_id + '/ACTIVE'
                            );
                        }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Jobs Active :</b>
                        </span>{' '}
                        {user.jobsActive || 0}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                          if (parseInt(user.jobsClosed, 10) > 0)
                            ogprops.history.push(
                              '/jobs-by-user/' + user.user_id + '/INACTIVE'
                            );
                        }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Jobs Closed : </b>
                        </span>{' '}
                        {user.jobsClosed || 0}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                          if (parseInt(user.jobsRejected, 10) > 0)
                            ogprops.history.push(
                              '/jobs-by-user/' + user.user_id + '/REJECTED'
                            );
                        }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Jobs Rejected :</b>
                        </span>{' '}
                        {user.jobsRejected || 0}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                          if (parseInt(user.jobsUnderReview, 10) > 0)
                            ogprops.history.push(
                              '/jobs-by-user/' + user.user_id + '/UNDER_REVIEW'
                            );
                        }}>
                        <span
                          style={{ color: '#363130', marginRight: '.2rem' }}>
                          <b>Jobs Under Review :</b>
                        </span>{' '}
                        {user.jobsUnderReview || 0}
                      </div>
                    </Typography>

                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Unlocked Via Search:</b>
                      </span>{' '}
                      {user.OffersSent}
                    </Typography>
                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Unlocked Via Job:</b>
                      </span>{' '}
                      {user.unlockedViaJob}
                    </Typography>
                    {/* <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Offers Sent And Accepted:</b>
                      </span>{' '}
                      {user.OffersSentAccepted}
                    </Typography> */}
                  </>
                )}
                {(user.status === 2 || selectedMode === 'MEMBER') && (
                  <>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> Preferred Work Locations :</b>
                      </span>{' '}
                      {user.preferredLocations
                        ?.map((item) => item.city)
                        .join(' | ')}
                    </Typography>{' '}
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> Want to work remote :</b>
                      </span>{' '}
                      {user.wantToWorkRemote ? 'YES' : 'NO'}
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> Highest Qualification :</b>
                      </span>{' '}
                      {user.highest_qualification}
                    </Typography>
                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Highest Qualification Passing Year :</b>
                      </span>{' '}
                      {user.highestEducationData?.passingYear}
                    </Typography>
                    {user.hourly_compensation && (
                      <Typography
                        gutterBottom
                        color="textSecondary"
                        variant="h6">
                        <span style={{ color: '#363130' }}>
                          <b> Hourly Compensation:</b>
                        </span>{' '}
                        {user.hourly_compensation}
                      </Typography>
                    )}
                    {user.monthly_compensation && (
                      <Typography
                        gutterBottom
                        color="textSecondary"
                        variant="h6">
                        <span style={{ color: '#363130' }}>
                          <b> Monthly Compensation:</b>
                        </span>{' '}
                        {user.monthly_compensation}
                      </Typography>
                    )}
                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Seeked Opportunities:</b>
                      </span>{' '}
                      {user.userSeekedOpportunities
                        ?.map((item) => item.opportunity)
                        .join(', ')}
                    </Typography>
                    {user.workExpCount && user.workExperienceRange && (
                      <Typography
                        gutterBottom
                        color="textSecondary"
                        variant="h6">
                        <span style={{ color: '#363130' }}>
                          <b> Work Experience:</b>
                        </span>{' '}
                        {user.workExperienceRange.lowerworkex} -{' '}
                        {user.workExperienceRange.upperworkex} Years
                      </Typography>
                    )}
                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Current Availabilty:</b>
                      </span>{' '}
                      {user.current_availability}
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#363130' }}>
                          <b> Purpose :</b>
                        </span>{' '}
                        {user.lookingFor}
                        {(user.lookingFor === 'Hiring' ||
                          user.lookingFor === 'Full-Time Work') && (
                          <span
                            style={{
                              marginLeft: '.5rem',
                              cursor: 'pointer',
                              marginTop: '.1rem'
                            }}>
                            <span onClick={() => setShowPurposeModal(true)}>
                              <CreateIcon />
                            </span>
                          </span>
                        )}
                      </div>
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> Coins :</b>
                      </span>{' '}
                      {user.karma_points}
                    </Typography>
                    <Typography gutterBottom color="textSecondary" variant="h6">
                      <span style={{ color: '#363130' }}>
                        <b> Age:</b>
                      </span>{' '}
                      {user.dob == null
                        ? 'NA'
                        : moment().diff(user.dob, 'years', false)}
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> BIO : </b>
                      </span>{' '}
                      {user.bio}
                    </Typography>
                    <Typography gutterBottom variant="h6" color="textSecondary">
                      <span style={{ color: '#363130' }}>
                        <b> Career Roadmap : </b>
                      </span>{' '}
                      {dataFromResume.suggestedCareerRoadmap || ''}
                    </Typography>
                  </>
                )}
                <Typography gutterBottom variant="h6" color="textSecondary">
                  <span style={{ color: '#363130' }}>
                    <b> Last Session :</b>
                  </span>{' '}
                  {moment(user.lastSession + ' UTC').fromNow()}
                </Typography>
                <Typography gutterBottom variant="h6" color="textSecondary">
                  <span style={{ color: '#363130' }}>
                    <b> Joined On :</b>
                  </span>{' '}
                  {moment(user.created_at).format('DD-MM-YYYY HH:mm')}
                </Typography>
                <Typography gutterBottom variant="h6" color="textSecondary">
                  <span style={{ color: '#363130' }}>
                    <b>Source :</b>
                  </span>{' '}
                  <span
                    onClick={() => {
                      if (user.source_user_id)
                        ogprops.history.push(`/account/${user.source_user_id}`);
                      window.location.reload();
                    }}
                    style={user?.source_user_id ? { cursor: 'pointer' } : null}>
                    {user?.source}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {selectedMode !== 'HIRING' && (
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h4">
                Goodness Score from{' '}
                <span style={rcStyle}>{user.reviewCount || 0} </span> feedback
              </Typography>
              <Divider />
              <Typography className={classes.progress} variant="body1">
                Overall : {user.score}
              </Typography>
              <LinearProgress
                value={Math.round(user.score * 10)}
                variant="determinate"
                className={classes.progress}
              />
              {parameters.map((item, key) => (
                <div className={classes.progress}>
                  <Typography variant="body1">
                    {item.parameter}: {item.score}
                  </Typography>
                  <LinearProgress
                    value={Math.round(item.score * 10)}
                    variant="determinate"
                  />
                </div>
              ))}
              <Button
                style={{
                  background: 'blue',
                  color: 'white',
                  marginLeft: '1rem'
                }}
                onClick={() => setConfirmBlock(true)}>
                {user.status == 5 ? 'Unblock' : 'Block'}{' '}
              </Button>
              <Button
                style={{
                  background: 'blue',
                  color: 'white',
                  marginLeft: '2rem'
                }}
                onClick={() => setConfirmDeactivate(true)}>
                {user.status == 6 ? 'Activate' : 'Deactivate'}{' '}
              </Button>
            </Grid>
          )}
          {selectedMode === 'HIRING' && (
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h4">
                RCoins Purchase History
                {/* <button> Add Coins</button> */}
              </Typography>
              <Divider />
              <MaterialTable
                isLoading={!rcoinHistory}
                columns={[
                  {
                    title: 'Date',
                    field: 'time'
                  },
                  {
                    title: 'R-Coins',
                    field: 'overriden_gcoin_value'
                  },
                  {
                    title: 'Amount',
                    field: 'amount'
                  }
                ]}
                data={rcoinHistory || []}
                // totalCount={totalCount}
                // page={page}
                title=""
                // onChangePage={(e) => {
                //   console.log('Changing page - ' + e);
                //   setPage(e);
                // }}
                options={{
                  search: false,
                  actionsColumnIndex: -1,

                  pageSizeOptions: [10, 20, 30],
                  sorting: false
                }}
              />

              <br />
              <Button
                style={{
                  background: 'blue',
                  color: 'white',
                  marginLeft: '1rem'
                }}
                onClick={() => setConfirmBlock(true)}>
                {user.status == 5 ? 'Unblock' : 'Block'}{' '}
              </Button>
              <Button
                style={{
                  background: 'blue',
                  color: 'white',
                  marginLeft: '2rem'
                }}
                onClick={() => setConfirmDeactivate(true)}>
                {user.status == 6 ? 'Activate' : 'Deactivate'}{' '}
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
      <ReactModal
        isOpen={showPurposeModal}
        className="modal-class"
        overlayClassName="overlay-class"
        onRequestClose={() => setShowPurposeModal(false)}>
        <div className="purposeContainer">
          <div className="purposeLabel">Change User Purpose</div>
          <ReactSelect
            value={purposeVal}
            options={[
              { label: 'Hiring', value: 18 },
              { label: 'Full-Time Work', value: 27 }
            ]}
            onChange={(e) => setPurposeVal(e)}
          />
          <button className="purposeButton" onClick={changePurpose}>
            Save
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={confirmBlock}
        className="modal-class"
        overlayClassName="overlay-class">
        <div className="modalContainer">
          Are you sure you want to {user.status == 5 ? 'unblock' : 'block'}{' '}
          {user.name}?
          <div className="modalBtns">
            <button className="modal-accept" onClick={handleBlock}>
              {user.status == 5 ? 'Unblock' : 'Block'}
            </button>
            <button
              className="modal-cancel"
              onClick={() => setConfirmBlock(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={confirmOfferBlock}
        className="modal-class"
        overlayClassName="overlay-class">
        <div className="modalContainer">
          Are you sure you want to{' '}
          {user.sendOfferDisabled
            ? 'allow ' + user.name + ' to send offers?'
            : 'forbid ' + user.name + ' from sending offers?'}
          <div className="modalBtns">
            <button className="modal-accept" onClick={handleOfferBlock}>
              {user.sendOfferDisabled ? 'Allow' : 'Forbid'}
            </button>
            <button
              className="modal-cancel"
              onClick={() => setConfirmOfferBlock(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
      {/* <ReactModal
        isOpen={true || showAddGcoins}
        className="modal-class"
        overlayClassName="overlay-class">
        <div className="modalContainer">
          <div className="modalLabel">Give R-Coins to Recruiter</div>
          <div className="modalInput">
            <input
              style={{
                width: '100%',
                height: '2rem',
                margin: '2rem 0',
                borderRadius: '5px',
                border: '1px solid #ccc',
                padding: '0.45rem .8rem'
              }}
              type="number"
              value={gcoinsToAdd}
              onChange={(e) => gcoinsToAdd(e.target.value)}
            />
          </div>
          <button className="modal-accept" onClick={null}>
            Add
          </button>
          <button
            className="modal-cancel"
            onClick={() => setShowAddGcoins(false)}>
            Cancel
          </button>
        </div>
      </ReactModal> */}
      <ReactModal
        isOpen={confirmDeactivate}
        className="modal-class"
        overlayClassName="overlay-class">
        <div className="modalContainer">
          Are you sure you want to{' '}
          {user.status == 6 ? 'Activate' : 'Deactivate'} {user.name}'s account?
          <div className="modalBtns">
            <button className="modal-accept" onClick={handleDeactivate}>
              {user.status == 6 ? 'Activate' : 'Deactivate'}
            </button>
            <button
              className="modal-cancel"
              onClick={() => setConfirmDeactivate(false)}>
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

UserAccountDetails.propTypes = {
  className: PropTypes.string
};

export default UserAccountDetails;
