import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MaterialTable from 'material-table';
import { parseISO, format } from 'date-fns';
import { sendHttpCall } from 'shareService/httpService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import RefreshIcon from '@material-ui/icons/Refresh';

// Initialize toast notifications
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(1, 3),
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    '&:hover': {
      opacity: 0.8
    }
  },
  activeUsersButton: {
    backgroundColor: '#ccc',
    color: '#000',
    '&.selected': {
      backgroundColor: '#657da3'
    }
  },
  inactiveUsersButton: {
    backgroundColor: '#ccc',
    color: '#000',
    '&.selected': {
      backgroundColor: '#657da3'
    }
  },
  createUserButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#388e3c'
    }
  },
  deactivateButton: {
    color: 'red',
    cursor: 'pointer'
  },
  activateButton: {
    color: 'green',
    cursor: 'pointer'
  },
  editButton: {
    color: '#3f51b5',
    cursor: 'pointer'
  },
  refreshButton: {
    color: '#ff9800',
    cursor: 'pointer'
  }
}));

const ViewAllAdmins = () => {
  const classes = useStyles();
  const [adminUsers, setAdminUsers] = useState([]);
  const [status, setStatus] = useState('ACTIVE');
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState({
    name: '',
    emailPrefix: '',
    type: 'Admin' // Default type
  });

  const [actionType, setActionType] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  useEffect(() => {
    getAdminUsers(status);
  }, [status]);

  const getAdminUsers = () => {
    let url = `admin/getAdminUsers/${status}`;
    sendHttpCall('GET', url, null).then((response) => {
      if (response.success && response.data) {
        const dataWithFormattedDate = response.data.map((item, index) => ({
          ...item,
          last_login: format(parseISO(item.last_login), 'dd MMM, yy | h:mm a'),
          serial: index + 1,
          emailPrefix: item.email_id.split('@')[0]
        }));
        setAdminUsers(dataWithFormattedDate);
      } else {
        setAdminUsers([]);
      }
    });
  };

  const updateAdminUserStatus = (adminId, newStatus) => {
    const url = `admin/updateDetails/${adminId}`;
    const data = { status: newStatus };

    sendHttpCall('PUT', url, data)
      .then((response) => {
        if (response.success) {
          toast.success('Status updated successfully');
          getAdminUsers(); // Refresh the admin users list
        } else {
          toast.error(`Failed to update status: ${response.message}`);
        }
      })
      .catch((error) => {
        toast.error(`Error updating status: ${error.message}`);
      });
  };

  const handleDeactivate = (adminId) => {
    updateAdminUserStatus(adminId, 'INACTIVE');
  };

  const handleActivate = (adminId) => {
    updateAdminUserStatus(adminId, 'ACTIVE');
  };

  const handleOpenDialog = (user = null) => {
    if (user) {
      setEditMode(true);
      setSelectedUser(user);
      setNewUser({
        name: user.name,
        emailPrefix: user.emailPrefix,
        type: user.type
      });
    } else {
      setEditMode(false);
      setNewUser({ name: '', emailPrefix: '', type: 'Admin' });
    }
    setOpenDialog(true);
  };

  const handleOpenAlertDialog = (user, type) => {
    if (user) {
      console.log(user);
      setSelectedUser(user);
    }
    setOpenAlertDialog(true);
    setActionType(type);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleCreateOrUpdateUser = () => {
    if (editMode && selectedUser) {
      // Update existing user
      const email = `${newUser.emailPrefix}@goodspace.ai`;
      const data = {
        name: newUser.name,
        email_id: email,
        type: newUser.type
      };

      const url = `admin/updateDetails/${selectedUser.admin_id}`;

      sendHttpCall('PUT', url, data)
        .then((response) => {
          if (response.success) {
            toast.success('User updated successfully');
            getAdminUsers(); // Refresh the admin users list
            handleCloseDialog(); // Close the dialog
          } else {
            toast.error(`Failed to update user: ${response.message}`);
          }
        })
        .catch((error) => {
          toast.error(`Error updating user: ${error.message}`);
        });
    } else {
      // Create new user logic (as before)
      const email = `${newUser.emailPrefix}@goodspace.ai`;
      const password = generateStrongPassword(newUser.name);
      const data = {
        name: newUser.name,
        email_id: email,
        type: newUser.type,
        password: password
      };

      const url = `admin/createAdmin`;

      sendHttpCall('POST', url, data)
        .then((response) => {
          if (response.success) {
            toast.success('User created successfully');
            // Copy credentials to clipboard
            const credentials = `Email: ${email}\nPassword: ${password}`;
            navigator.clipboard
              .writeText(credentials)
              .then(() => {
                toast.success('Credentials copied to clipboard');
              })
              .catch((err) => {
                toast.error('Failed to copy credentials to clipboard');
                console.error('Failed to copy credentials to clipboard:', err);
              });

            getAdminUsers(); // Refresh the admin users list
            handleCloseDialog(); // Close the dialog
          } else {
            toast.error(`Failed to create user: ${response.message}`);
          }
        })
        .catch((error) => {
          toast.error(`Error creating user: ${error.message}`);
        });
    }
  };

  const handleRegeneratePassword = (adminId) => {
    const newPassword = generateStrongPassword(selectedUser.name);
    const url = `admin/updateDetails/${adminId}`;
    const data = { password: newPassword };
    sendHttpCall('PUT', url, data)
      .then((response) => {
        if (response.success) {
          toast.success(`${selectedUser.name}'s Password regenerated successfully`);
          // Copy new password to clipboard
          navigator.clipboard
            .writeText(newPassword)
            .then(() => {
              toast.success('New password copied to clipboard');
            })
            .catch((err) => {
              toast.error('Failed to copy password to clipboard');
              console.error('Failed to copy password to clipboard:', err);
            });
        } else {
          toast.error(`Failed to regenerate password: ${response.message}`);
        }
      })
      .catch((error) => {
        toast.error(`Error regenerating password: ${error.message}`);
      });
  };

  const generateStrongPassword = (name, length = 12) => {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()_+~`|}{[]:;?><,./-=';

    const allCharacters = uppercase + lowercase + numbers + symbols;

    let password = '';

    // Ensure the password contains at least one of each type of character
    //password += uppercase[Math.floor(Math.random() * uppercase.length)];
    //password += lowercase[Math.floor(Math.random() * lowercase.length)];
    //password += numbers[Math.floor(Math.random() * numbers.length)];
    password += (name.toLowerCase()) + '123';

    // Fill the rest of the password length with random characters
    // for (let i = password.length; i < length; i++) {
    //   password +=
    //     allCharacters[Math.floor(Math.random() * allCharacters.length)];
    // }

    // // Shuffle the characters in the password to ensure randomness
    // password = password
    //   .split('')
    //   .sort(() => 0.5 - Math.random())
    //   .join('');

    console.log('Generated Password: ', password);
    return password;
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.buttonContainer}>

          <button
            className={`${classes.button} ${classes.activeUsersButton} ${status === 'ACTIVE' ? 'selected' : ''
              }`}
            onClick={() => setStatus('ACTIVE')}>
            Active Users
          </button>
          <button
            className={`${classes.button} ${classes.inactiveUsersButton} ${status === 'INACTIVE' ? 'selected' : ''
              }`}
            onClick={() => setStatus('INACTIVE')}>
            Inactive Users
          </button>
          <button
            className={`${classes.button} ${classes.createUserButton}`}
            onClick={() => handleOpenDialog()}>
            Create New User
          </button>
        </div>
        <AdminTable
          data={adminUsers}
          status={status}
          onDeactivate={handleOpenAlertDialog}
          onActivate={handleActivate}
          onEdit={handleOpenDialog}
          onRegeneratePassword={handleOpenAlertDialog}
        />

        {/* Dialog for creating/updating a user */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {editMode ? 'Edit User' : 'Create New User'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              name="name"
              fullWidth
              value={newUser.name}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              label="Email"
              name="emailPrefix"
              fullWidth
              value={newUser.emailPrefix}
              onChange={handleInputChange}
              helperText="@goodspace.ai"
            />
            <TextField
              margin="dense"
              label="Type"
              name="type"
              select
              fullWidth
              value={newUser.type}
              onChange={handleInputChange}>
              <MenuItem value="ADMIN">Admin</MenuItem>
              <MenuItem value="SALES">Sales</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateOrUpdateUser} color="primary">
              {editMode ? 'Save' : 'Create'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Regenerate Password Confirmation Dialog */}
        <Dialog open={openAlertDialog} onClose={() => setOpenAlertDialog(false)}>
          <DialogTitle>{actionType === 'regenerate' ? 'Regenerate Password' : 'Deactivate user'}</DialogTitle>
          <DialogContent>
            {selectedUser && selectedUser.name ? (
              <>
                Are you sure you want to {actionType === 'regenerate' ? (
                  <>regenerate <strong>{selectedUser.name}</strong>'s password</>
                ) : (
                  <>deactivate <strong>{selectedUser.name}</strong>'s account</>
                )}
                ?
              </>
            ) : (
              <span>Loading...</span>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpenAlertDialog(false)} color="primary">
              No
            </Button>
            <Button
              onClick={() => {
                if (actionType === 'regenerate') {
                  handleRegeneratePassword(selectedUser.admin_id);
                } else {
                  handleDeactivate(selectedUser.admin_id);
                }

                setOpenAlertDialog(false);
              }}
              color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>



      </div >

    </>
  );
};

const AdminTable = ({
  data,
  status,
  onDeactivate,
  onActivate,
  onEdit,
  onRegeneratePassword
}) => {
  const classes = useStyles();

  return (
    <MaterialTable
      title={status == 'ACTIVE' ? 'Active Admin Users' : 'Inactive Admin Users'}
      columns={[
        { title: 'Serial No.', field: 'serial', type: 'numeric' },
        { title: 'Name', field: 'name' },
        { title: 'Admin ID', field: 'admin_id', type: 'numeric' },
        { title: 'Email', field: 'email_id' },
        { title: 'Type', field: 'type' },
        { title: 'Last Login', field: 'last_login', type: 'datetime' },
        {
          title: 'Action',
          render: (rowData) =>
            status === 'ACTIVE' ? (
              <>
                <IconButton
                  className={classes.deactivateButton}
                  onClick={() => onDeactivate(rowData, 'deactivate')}>
                  <PowerSettingsNewIcon />
                </IconButton>
                <IconButton
                  className={classes.editButton}
                  onClick={() => onEdit(rowData)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={classes.refreshButton}
                  onClick={() => onRegeneratePassword(rowData, 'regenerate')}>
                  <RefreshIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  className={classes.activateButton}
                  onClick={() => onActivate(rowData.admin_id)}>
                  <PowerSettingsNewIcon />
                </IconButton>
                <IconButton
                  className={classes.editButton}
                  onClick={() => onEdit(rowData)}>
                  <EditIcon />
                </IconButton>
                <IconButton
                  className={classes.refreshButton}
                  onClick={() => onRegeneratePassword(rowData, 'regenerate')}>
                  <RefreshIcon />
                </IconButton>
              </>
            )
        }
      ]}
      data={data}
      options={{
        search: true,
        paging: false // Disable pagination to show all records
      }}
    />
  );
};

export default ViewAllAdmins;
