import React, { useState, useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { RouteWithLayout } from './components';
import ClickTracking from 'views/ClickTracking/ClickTracking';
import NotificationsTracking from 'views/ClickTracking/NotificationsTracking';
import AlertsReportDownload from 'views/AlertsReportDownload/AlertsReportDownload';
import StartedInterviews from 'views/Interviews/StartedInterviews';
import StuckInterviews from 'views/Interviews/StuckInterviews';
import FinishedInterviews from 'views/Interviews/FinishedInterviews';
import RohanBotLogs from 'views/RohanBotLogs/RohanBotLogs';
import RohanBotChats from 'views/RohanBotLogs/RohanChats';
import SakshiBotChats from 'views/SakshiBotLogs/SakshiChats';
import SakshiBotLogs from 'views/SakshiBotLogs/SakshiBotLogs';
import RecommendedCandidates from 'views/Interviews/RecommendedCandidates';
import InterviewCounts from 'views/Interviews/InterviewCounts';
import InterviewData from 'views/Interviews/InterviewData';
import InterviewDetails from 'views/Interviews/InterviewDetails';
import OnboardingChatDetails from 'views/Interviews/OnboardingChatDetails';
import ClickTrackingSource from 'views/ClickTracking/ClickTrackingBySource';
import ClickTrackingByLink from 'views/ClickTracking/ClickTrackingByLink';
import WhatsAppCommunications from 'views/Communications/WhatsappCommunications';
import Opportunities from 'views/Sales/Opportunities';
import Leads from 'views/Sales/Leads';
import AddLead from 'views/Sales/AddLead';
import SalesDashboard from 'views/Sales/SalesDashboard';
import SendNotification from 'views/Sales/SendNotification';
import SalesReports from 'views/Sales/SalesReports';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import BlogDetails from './views/blog/details/index';
import EditBlogs from './views/blog/edit/index';
import KpiPage from './views/Dashboard/KpiPage';
import {
  Dashboard as DashboardView,
  TopSkill as TopSkillView,
  TopDesignation as TopDesignationView,
  socialmedia as socialmedia,
  socialmedialist as socialmedialist,
  SkillUsersList as SkillUsersListView,
  UserEngagement as UserEngagementView,
  //ProductList as ProductListView,
  Typography as TypographyView,
  Icons as IconsView,
  UserDetail as UserDetailView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ExpansionSideNav as ExpansionSideNav
} from './views';
import DashboardOld from 'views/Dashboard/DashboardOld';
import JobDetail  from './views/JobDetail/JobDetail'
import GCoins from './views/Dashboard/components/GCoins/GCoins';
import DashboardUsa from './views/Dashboard/components/USA/USADashboard';
import DashboardUK from './views/Dashboard/components/UK/UKDashboard';
import VolunteerData from './views/Dashboard/components/VolunteerData/VolunteerData';
import VolunteerDataList from './views/Dashboard/components/VolunteerData/VolunteerDataList';
import {
  BusinessServiceType as BusinessServiceType,
  Degrees as Degrees,
  DegreeLevels as DegreeLevels,
  DegreeFields as DegreeFields,
  Designations as Designations,
  Fitnesses as Fitnesses,
  HabitFrequencies as HabitFrequencies,
  IndustryTypes as IndustryTypes,
  Interests as Interests,
  PoliticalViews as PoliticalViews,
  RelationshipStatus as RelationshipStatus,
  Religions as Religions,
  Purposes as Purposes,
  Skills as Skills,
  TzPoints as TzPoints,
  Relationships as Relationships
} from './views/Masters';
import { ZeroCountryCode as ZeroCountryCodeView } from './views/DBCorruptions';
import ChatsView from './views/Chats';
import {
  ActiveUsersList as ActiveUsersListView,
  VerifiedUsersList as VerifiedUsersListView,
  UserRatingAnalytics as UserRatingAnalyticsView,
  DownloadCount as DownloadCountView,
  LinkClicks as LinkClicksView,
  ApplicationExpired as ApplicationExpiredView,
  ApplicationAccepted as ApplicationAcceptedView,
  JobsApplication as JobsApplicationView,
  NoJobs as NoJobsView,
  JobPostings as JobPostingsView,
  Hire as HireView,
  InactiveUsersList as InactiveUsersListView,
  UserCountByCity as UserCountByCityView,
  UserSearch as UserSearchView,
  ActiveSocialUsers as ActiveSocialUsersView,
  HirerSearch as HirerSearchView,
  UserRequest as UserRequestView,
  UserMasterRequest as UserMasterRequestView,
  Status2SocialUsers as Status2SocialUsersView,
  NewJoinRequestList as NewJoinRequestListView,
  ReportedJobs,
  RedirectJobClick
} from './views/UserList';
import candidateFeedback from 'views/candidateFeedback';
import { AuxCardsList as AuxCardsListView } from './views/Cards';
import { LogsExplorer } from 'views';
import MarketingCampaigns from 'views/MarketingCampaigns';
import CreatePaymentLink from 'views/PaymentServices/createPaymentLink';
import ManualPaymentGateway from 'views/PaymentServices/manualPaymentGateway';
import AddCampaign from 'views/MarketingCampaigns/AddCampaign';
import UsersByCampaign from 'views/MarketingCampaigns/UsersByCampaign';
import RedirectLinkData from 'views/MarketingCampaigns/RedirectLinkData';
import PromoCodes from 'views/PromoCodes';
import AddPromoCode from 'views/PromoCodes/AddPromoCode';
import ViewJobsUnderReview from 'views/JobPosts/ViewJobsUnderReview';
import ViewJobsOnHold from 'views/JobPosts/ViewJobsOnHold';
import ViewAllActiveJobs from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview';
import FullMemberList from 'views/UserList/FullMemberList';
import FeedbackRequestData from 'views/UserList/FeedbackRequestData';
import ReportGenerator from 'views/Dashboard/components/ReportGenerator';
import SurveyMessage from 'views/SurveyMessage';
import EveryTable  from 'views/Dashboard/components/EveryTable';
import ViewAllClosedJobs from 'views/JobPosts/ViewAllClosedJobs';
import AddJob from 'views/JobPosts/AddJob/AddJob';
import DesignationUsersList from 'views/DesignationUsersList';
import ViewSocialExpUnderReview from 'views/SocialExpPosts/ViewSocialExpUnderReview';
import ViewSocialActiveExp from 'views/SocialExpPosts/ViewActiveSocialExp';
import ApplicationsSource from './views/Dashboard/components/ApplicationsSource/ApplicationsSource'
import {
  GCoinsTransactionsByUser,
  GCoinsTransactionsGrouped
} from 'views/GCoins';
import ViewAllJobsByUserAndType from 'views/JobPosts/ViewAllActiveJobs/ViewJobsUnderReview/ViewAllJobsByUserAndType';
import Status0Search from 'views/Status0Search/Status0Search';
import PotentialCandidates from 'views/UserList/JobsApplication/PotentialCandidates';
import PotentialCandidatess3 from 'views/UserList/JobsApplication/PotentialCandidatess3';
import PotentialCandidatess3Rerank from 'views/UserList/JobsApplication/PotentialCandidatess3Rerank';
import PotentialCandidatesvs3 from 'views/UserList/JobsApplication/Potentials3VectorUsers';
import PotentialCandidatess8m from 'views/UserList/JobsApplication/PotentialCandidates8m'
import PotentialCandidatess8e from 'views/UserList/JobsApplication/PotentialCandidatess8e'
import PotentialCandidatess8EmailFullText from 'views/UserList/JobsApplication/PotentialCandidateUser8FullText'
import PotentialCandidatesAi from 'views/UserList/JobsApplication/PotentialCandidatesAi';
import PitchUnderReview from 'views/PitchUnderReview/PitchUnderReview';
import IntroUnderReview from 'views/PitchUnderReview/IntroUnderReview';
import PitchActive from 'views/PitchUnderReview/PitchActive';
import ViewAllAdmins from 'views/Admins/Admins';

const Routes = (props) => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to={
          localStorage.getItem('user_type') === 'MARKETING'
            ? '/report'
            : props.path
        }
      />
{/* MARKETING ONLY ROLES */}
      {
        localStorage.getItem('user_type') === 'MARKETING' ?
        <>
        <RouteWithLayout
          component={ReportGenerator}
          exact
          layout={MainLayout}
          path="/"
        />
        <RouteWithLayout
          component={EveryTable}
          exact
          layout={MainLayout}
          path="/data/:type/:period/:start_date/:end_date"
        />
        <RouteWithLayout
        component={UserDetailView}
        exact
        layout={MainLayout}
        path="/account/:userid"
      />
        </>
        :
        <RouteWithLayout
          component={ReportGenerator}
          exact
          layout={MainLayout}
          path="/report"
        />
      }
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={UserSearchView}
        exact
        layout={MainLayout}
        path="/search-user"
      />
      <RouteWithLayout
        component={ActiveSocialUsersView}
        exact
        layout={MainLayout}
        path="/search-social-user"
      />
      <RouteWithLayout
        component={GCoinsTransactionsByUser}
        exact
        layout={MainLayout}
        path="/gcoins/transactions/:code"
      />
      <RouteWithLayout
        component={NotificationsTracking}
        exact
        layout={MainLayout}
        path="/tracking/notifications"
      />
      <RouteWithLayout
        component={AlertsReportDownload}
        exact
        layout={MainLayout}
        path="/tracking/alerts-report-generator"
      />
      <RouteWithLayout
        component={StartedInterviews}
        exact
        layout={MainLayout}
        path="/started-interviews/:start_date/:end_date"
      />
       <RouteWithLayout
        component={StuckInterviews}
        exact
        layout={MainLayout}
        path="/stuck-interviews/:start_date/:end_date"
      />
      <RouteWithLayout
        component={FinishedInterviews}
        exact
        layout={MainLayout}
        path="/finished-interviews/:start_date/:end_date"
      />
      <RouteWithLayout
        component={RohanBotLogs}
        exact
        layout={MainLayout}
        path="/rohan-chatbot-logs/:start_date/:end_date"
      />
      <RouteWithLayout
        component={RohanBotChats}
        exact
        layout={MainLayout}
        path="/rohan-chats/:userId"
      />
      <RouteWithLayout
        component={SakshiBotLogs}
        exact
        layout={MainLayout}
        path="/sakshi-chatbot-logs/:start_date/:end_date"
      />
      <RouteWithLayout
        component={SakshiBotChats}
        exact
        layout={MainLayout}
        path="/sakshi-chats/:userId"
      />
      <RouteWithLayout
        component={RecommendedCandidates}
        exact
        layout={MainLayout}
        path="/recommended-candidates/:start_date/:end_date"
      />
      <RouteWithLayout
        component={InterviewCounts}
        exact
        layout={MainLayout}
        path="/auto-interview"
      />
      <RouteWithLayout
        component={InterviewData}
        exact
        layout={MainLayout}
        path="/auto-interview/:type/:start_date/:end_date"
      />
      <RouteWithLayout
        component={InterviewDetails}
        exact
        layout={MainLayout}
        path="/auto-interview/interview/:interviewId"
      />
      <RouteWithLayout
        component={OnboardingChatDetails}
        exact
        layout={MainLayout}
        path="/onboarding-chat/:userId"
      />
      <RouteWithLayout
        component={PotentialCandidates}
        exact
        layout={MainLayout}
        path="/job/potential_candidates/:offerId"
      />
      {/* <RouteWithLayout
        component={PotentialCandidatesAi}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_ai/:offerId"
      /> */}
      <RouteWithLayout
        component={PotentialCandidatess3}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_s3/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatess3Rerank}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_s3_rerank/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatesAi}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_ai_cutoff/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatesvs3}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_s3_vector/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatess8m}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_s8m/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatess8e}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_s8e/:offerId"
      />
      <RouteWithLayout
        component={PotentialCandidatess8EmailFullText}
        exact
        layout={MainLayout}
        path="/job/potential_candidates_user8_full_text/:offerId"
      />
      <RouteWithLayout
        component={Status0Search}
        exact
        layout={MainLayout}
        path="/search-contact-db"
      />
      <RouteWithLayout
        component={ClickTracking}
        exact
        layout={MainLayout}
        path="/tracking/:type"
      />
      <RouteWithLayout
        component={ClickTrackingSource}
        exact
        layout={MainLayout}
        path="/tracking/:type/:source/:startDate/:endDate"
      />
      <RouteWithLayout
        component={ClickTrackingByLink}
        exact
        layout={MainLayout}
        path="/tracking/:type/:source/:link/:startDate/:endDate/users"
      />
      <RouteWithLayout
        component={WhatsAppCommunications}
        exact
        layout={MainLayout}
        path="/communications/:type"
      />
      <RouteWithLayout
        component={Opportunities}
        exact
        layout={MainLayout}
        path="/opportunities"
      />
      <RouteWithLayout
        component={Leads}
        exact
        layout={MainLayout}
        path="/leads"
      />
      <RouteWithLayout
        component={AddLead}
        exact
        layout={MainLayout}
        path="/add-lead"
      />
      <RouteWithLayout
        component={SalesDashboard}
        exact
        layout={MainLayout}
        path="/sales-dashboard"
      />
      <RouteWithLayout
        component={SendNotification}
        exact
        layout={MainLayout}
        path="/push-notifications"
      />
      <RouteWithLayout
        component={SalesReports}
        exact
        layout={MainLayout}
        path="/sales-reports"
      />
       <RouteWithLayout
        component={WhatsAppCommunications}
        exact
        layout={MainLayout}
        path="/communications/email"
      />
      <RouteWithLayout
        component={GCoinsTransactionsGrouped}
        exact
        layout={MainLayout}
        path="/gcoins/:type"
      />
      <RouteWithLayout
        component={Status2SocialUsersView}
        exact
        layout={MainLayout}
        path="/incomplete-social-user"
      />
      <RouteWithLayout
        component={HirerSearchView}
        exact
        layout={MainLayout}
        path="/search-hirers"
      />
      <RouteWithLayout
        component={UserDetailView}
        exact
        layout={MainLayout}
        path="/account/:userid"
      />
       <RouteWithLayout
        component={JobDetail}
        exact
        layout={MainLayout}
        path="/job/:offerId"
      />
       <RouteWithLayout
        component={UserDetailView}
        exact
        layout={MainLayout}
        path="/rohanchat/:userid"
      />
       <RouteWithLayout
        component={UserDetailView}
        exact
        layout={MainLayout}
        path="/sakshichat/:userid"
      />
      <RouteWithLayout
          component={EveryTable}
          exact
          layout={MainLayout}
          path="/data/:type/:period/:start_date/:end_date"
        />
      <AdminRoutes />
      {/* <Redirect to="/ada" /> */}
    </Switch>
  );
};

export default Routes;
const AdminRoutes = () => {
  if (localStorage.getItem('user_type') === 'ADMIN' || localStorage.getItem('user_type') === 'SALES')
    return (
      <>
        {' '}
        <RouteWithLayout
          component={BlogDetails}
          exact
          layout={MainLayout}
          path="/blog-details"
        />
        <RouteWithLayout
          component={LogsExplorer}
          exact
          layout={MainLayout}
          path="/logs-explorer"
        />
        <RouteWithLayout
          component={EditBlogs}
          exact
          layout={MainLayout}
          path="/edit-blogs/:blog_id"
        />
        <RouteWithLayout
          component={EditBlogs}
          exact
          layout={MainLayout}
          path="/edit-blogs"
        />
        <RouteWithLayout
          component={DashboardView}
          exact
          layout={MainLayout}
          path="/dashboard"
        />
        <RouteWithLayout
          component={DashboardOld}
          exact
          layout={MainLayout}
          path="/dashboard-old"
        />
        <RouteWithLayout
          component={KpiPage}
          exact
          layout={MainLayout}
          path="/kpi"
        />
        <RouteWithLayout
          component={GCoins}
          exact
          layout={MainLayout}
          path="/gcoins"
        />
        <RouteWithLayout
          component={DashboardUsa}
          exact
          layout={MainLayout}
          path="/usa"
        />
         <RouteWithLayout
          component={ApplicationsSource}
          exact
          layout={MainLayout}
          path="/applications-source"
        />
        <RouteWithLayout
          component={DashboardUK}
          exact
          layout={MainLayout}
          path="/uk"
        />
        <RouteWithLayout
          component={VolunteerData}
          exact
          layout={MainLayout}
          path="/social-services"
        />
        <RouteWithLayout
          component={VolunteerDataList}
          exact
          layout={MainLayout}
          path="/social-services/:type"
        />
        <RouteWithLayout
          component={ReportGenerator}
          exact
          layout={MainLayout}
          path="/report"
        />

        <RouteWithLayout
          component={SurveyMessage}
          exact
          layout={MainLayout}
          path="/send_survey/:name/:userId"
        />
        <RouteWithLayout
          component={UserEngagementView}
          exact
          layout={MainLayout}
          path="/generations"
        />
        <RouteWithLayout
          component={TopSkillView}
          exact
          layout={MainLayout}
          path="/top-skills"
        />
        <RouteWithLayout
          component={TopDesignationView}
          exact
          layout={MainLayout}
          path="/top-job-titles"
        />
        <RouteWithLayout
          component={socialmedia}
          exact
          layout={MainLayout}
          path="/socialmedia"
        />
        <RouteWithLayout
          component={SkillUsersListView}
          exact
          layout={MainLayout}
          path="/skill-detail/:skill_id"
        />
        <RouteWithLayout
          component={DesignationUsersList}
          exact
          layout={MainLayout}
          path="/designation-detail/:skill_id"
        />
        <RouteWithLayout
          component={socialmedialist}
          exact
          layout={MainLayout}
          path="/social-users/:media/:start/:end"
        />
        <RouteWithLayout
          component={UserRequestView}
          exact
          layout={MainLayout}
          path="/use-resquest"
        />
        <RouteWithLayout
          component={UserMasterRequestView}
          exact
          layout={MainLayout}
          path="/use-master-resquest"
        />
        <RouteWithLayout
          component={TypographyView}
          exact
          layout={MainLayout}
          path="/typography"
        />
        <RouteWithLayout
          component={IconsView}
          exact
          layout={MainLayout}
          path="/icons"
        />
        <RouteWithLayout
          component={FullMemberList}
          exact
          layout={MainLayout}
          path="/full-members/:start_date/:end_date"
        />
        <RouteWithLayout
          component={FeedbackRequestData}
          exact
          layout={MainLayout}
          path="/feedback-requests/:start_date/:end_date"
        />
        
        <RouteWithLayout
          component={UserRatingAnalyticsView}
          exact
          layout={MainLayout}
          path="/dashboard/uan/:name/:user_id/:start_date/:end_date"
        />
        <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/settings"
        />
        <RouteWithLayout
          component={ExpansionSideNav}
          exact
          layout={MainLayout}
          path="/ExpansionSideNav"
        />
        <RouteWithLayout
          component={BusinessServiceType}
          exact
          layout={MainLayout}
          path="/business-service-type"
        />
        <RouteWithLayout
          component={DegreeLevels}
          exact
          layout={MainLayout}
          path="/degree-levels"
        />
        <RouteWithLayout
          component={DegreeFields}
          exact
          layout={MainLayout}
          path="/degree-fields"
        />
        <RouteWithLayout
          component={Degrees}
          exact
          layout={MainLayout}
          path="/degrees"
        />
        <RouteWithLayout
          component={Designations}
          exact
          layout={MainLayout}
          path="/designations"
        />
        <RouteWithLayout
          component={Fitnesses}
          exact
          layout={MainLayout}
          path="/fitnesses"
        />
        <RouteWithLayout
          component={HabitFrequencies}
          exact
          layout={MainLayout}
          path="/habit_frequencies"
        />
        <RouteWithLayout
          component={IndustryTypes}
          exact
          layout={MainLayout}
          path="/industry_types"
        />
        <RouteWithLayout
          component={Interests}
          exact
          layout={MainLayout}
          path="/intersets"
        />
        <RouteWithLayout
          component={PoliticalViews}
          exact
          layout={MainLayout}
          path="/political_views"
        />
        <RouteWithLayout
          component={RelationshipStatus}
          exact
          layout={MainLayout}
          path="/relationship_status"
        />
        <RouteWithLayout
          component={Relationships}
          exact
          layout={MainLayout}
          path="/relationships"
        />
        <RouteWithLayout
          component={Religions}
          exact
          layout={MainLayout}
          path="/religions"
        />
        <RouteWithLayout
          component={Purposes}
          exact
          layout={MainLayout}
          path="/purposes"
        />
        <RouteWithLayout
          component={Skills}
          exact
          layout={MainLayout}
          path="/skills"
        />
        <RouteWithLayout
          component={TzPoints}
          exact
          layout={MainLayout}
          path="/tz-points"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />
        <RouteWithLayout
          component={ZeroCountryCodeView}
          exact
          layout={MainLayout}
          path="/invalid-country-codes"
        />
        <RouteWithLayout
          component={NewJoinRequestListView}
          exact
          layout={MainLayout}
          path="/signups"
        />
        {/* <RouteWithLayout
          component={NewJoinRequestListView}
          exact
          layout={MainLayout}
          path="/signups"
        /> */}
        <RouteWithLayout
          component={ActiveUsersListView}
          exact
          layout={MainLayout}
          path="/active-users"
        />
        <RouteWithLayout
          component={ChatsView}
          exact
          layout={MainLayout}
          path="/chats/:chatType/:userIds"
        />
        <RouteWithLayout
          component={VerifiedUsersListView}
          exact
          layout={MainLayout}
          path="/verified-users/:start_date/:end_date"
        />
        <RouteWithLayout
          component={RedirectJobClick}
          exact
          layout={MainLayout}
          path="/redirect_jobs_clicks/unique/:offerId"
        />
        <RouteWithLayout
          component={RedirectJobClick}
          exact
          layout={MainLayout}
          path="/redirect_jobs_clicks/all/:offerId"
        />
        <RouteWithLayout
          component={ReportedJobs}
          exact
          layout={MainLayout}
          path="/reports/jobs/:offerId"
        />
        <RouteWithLayout
          component={HireView}
          exact
          layout={MainLayout}
          path="/hire/:type/:start_date/:end_date"
        />
        <RouteWithLayout
          component={JobPostingsView}
          exact
          layout={MainLayout}
          path="/jobs/:type/:start_date/:end_date"
        />
        <RouteWithLayout
          component={JobsApplicationView}
          exact
          layout={MainLayout}
          path="/job-application/:type/:start/:end"
        />
        <RouteWithLayout
          component={candidateFeedback}
          exact
          layout={MainLayout}
          path="/job-application/candidate/feedBack/:offerId/:userId"
        />
        <RouteWithLayout
          component={LinkClicksView}
          exact
          layout={MainLayout}
          path="/linkclicks/:start_date/:end_date"
        />
        <RouteWithLayout
          component={DownloadCountView}
          exact
          layout={MainLayout}
          path="/downloadcount/:start_date/:end_date"
        />
        <RouteWithLayout
          component={InactiveUsersListView}
          exact
          layout={MainLayout}
          path="/inactive-users"
        />
        <RouteWithLayout
          component={UserCountByCityView}
          exact
          layout={MainLayout}
          path="/user-count-by-city"
        />
        <RouteWithLayout
          component={PromoCodes}
          exact
          layout={MainLayout}
          path="/promo-codes"
        />
        <RouteWithLayout
          component={AddPromoCode}
          exact
          layout={MainLayout}
          path="/add-promo-code"
        />
        <RouteWithLayout
          component={MarketingCampaigns}
          exact
          layout={MainLayout}
          path="/marketing-campaigns"
        />
        <RouteWithLayout
          component={CreatePaymentLink}
          exact
          layout={MainLayout}
          path="/create-payment-link"
        />
        <RouteWithLayout
          component={ManualPaymentGateway}
          exact
          layout={MainLayout}
          path="/manual-payment-gateway"
        />
        <RouteWithLayout
          component={AddCampaign}
          exact
          layout={MainLayout}
          path="/add-marketing-campaign"
        />
        <RouteWithLayout
          component={UsersByCampaign}
          exact
          layout={MainLayout}
          path="/marketing-campaigns-user"
        />
        <RouteWithLayout
          component={RedirectLinkData}
          exact
          layout={MainLayout}
          path="/marketing-campaigns-clicks"
        />
        <RouteWithLayout
          component={AuxCardsListView}
          exact
          layout={MainLayout}
          path="/aux-cards"
        />
        <RouteWithLayout
          component={ViewJobsUnderReview}
          exact
          layout={MainLayout}
          path="/jobs-under-review/"
        />
        <RouteWithLayout
          component={ViewJobsOnHold}
          exact
          layout={MainLayout}
          path="/jobs-on-hold/"
        />
        <RouteWithLayout
          component={PitchUnderReview}
          exact
          layout={MainLayout}
          path="/pitch-under-review/"
        />
        <RouteWithLayout
          component={IntroUnderReview}
          exact
          layout={MainLayout}
          path="/intro-under-review/"
        />
        <RouteWithLayout
          component={PitchActive}
          exact
          layout={MainLayout}
          path="/pitch-all/"
        />
        <RouteWithLayout
          component={ViewSocialExpUnderReview}
          exact
          layout={MainLayout}
          path="/social-experience-under-review/"
        />
        <RouteWithLayout
          component={ViewSocialActiveExp}
          exact
          layout={MainLayout}
          path="/active-social-experience/"
        />
        <RouteWithLayout
          component={AddJob}
          exact
          layout={MainLayout}
          path="/add-job/"
        />
        <RouteWithLayout
          component={ViewJobsUnderReview}
          exact
          layout={MainLayout}
          path="/jobs-under-review/:start_date/:end_date"
        />
        <RouteWithLayout
          component={ViewJobsOnHold}
          exact
          layout={MainLayout}
          path="/jobs-on-hold/:start_date/:end_date"
        />
        <RouteWithLayout
          component={ViewAllActiveJobs}
          exact
          layout={MainLayout}
          path="/active-jobs"
        />
        <RouteWithLayout
          component={ViewAllJobsByUserAndType}
          exact
          layout={MainLayout}
          path="/jobs-by-user/:userId/:type"
        />
        <RouteWithLayout
          component={ViewAllClosedJobs}
          exact
          layout={MainLayout}
          path="/closed-jobs"
        />
        <RouteWithLayout
          component={ViewAllAdmins}
          exact
          layout={MainLayout}
          path="/manage-gs-admins"
        />
        {/* <Redirect to="/dashboard" /> */}
      </>
    );
  return <Redirect to="/report" />;
};
