import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import {
  Dashboard,
  ViewAgenda,
  ViewCarousel,
  Error,
  PersonAdd,
  Search,
  VerifiedUser,
  People,
  HourglassFullRounded,
  NotificationImportant,
  Timeline,
  ShowChart,
  MultilineChart,
  PieChart,
  SaveOutlined,
  Money,
  AirlineSeatLegroomExtraOutlined,
  Public,
  Group,
  Book,
  VerifiedUserSharp,
  Person,
  WhatsApp,
  Email,
  WhatshotSharp,
  Notifications
} from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';

import { Profile, SidebarNav, UpgradePlan } from './components';
import { InterviewIcon } from 'icons';
import ReportGenerator from 'views/Dashboard/components/ReportGenerator';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <Dashboard />
    },
    {
      title: 'Auto Interview ',
      href: '/auto-interview',
      icon: <InterviewIcon />
    },
    {
      title: 'KPI',
      href: '/kpi',
      icon: <ShowChart />
    },
    // {
    //   title: 'User Generation Data',
    //   href: '/generations',
    //   icon: <Dashboard />
    // },
    {
      title: 'Report Generator',
      href: '/report',
      icon: <PieChart />
    },
    // {
    //   title: 'GCoins',
    //   href: '/gcoins',
    //   icon: <Money />
    // },
    // {
    //   title: 'USA Data',
    //   href: '/usa',
    //   icon: <Public />
    // },
    {
      title: 'Applications Source',
      href: '/applications-source',
      icon: <Group />
    },
    // {
    //   title: 'UK Data',
    //   href: '/uk',
    //   icon: <Public />
    // },
    // {
    //   title: 'Social Services',
    //   href: '/social-services',
    //   icon: <Group />
    // },
    {
      title: 'Blog List ',
      href: '/blog-details',
      icon: <Book />
    },
    {
      title: 'Total Users ',
      href: '/logs-explorer',
      icon: <Person />
    }
  ];
  const CommunicationPages = [
    {
      title: 'Communications',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Whatsapp',
          href: '/communications/whatsapp',
          icon: <WhatsApp />
        },
        {
          title: 'Email',
          href: '/communications/email',
          icon: <Email />
        },

      ]
    }
  ];
  const SalesDashboardPages = [
    {
      title: 'Sales CRM',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Dashboard',
          href: '/sales-dashboard',
          icon: <Dashboard />
        },
        {
          title: 'Opportunities',
          href: '/opportunities',
          icon: <People />
        },
        {
          title: 'Leads',
          href: '/leads',
          icon: <People />
        },
        {
          title: 'Add Lead',
          href: '/add-lead',
          icon: <SettingsIcon />
        },
        ...(localStorage.getItem('user_type') === 'ADMIN' ? [
          {
            title: ' Send Notification',
            href: '/push-notifications',
            icon: <NotificationImportant />
          },
        
        ] : []),
        {
          title: ' Reports',
          href: '/sales-reports',
          icon: <People />
        },
      ]
    }
  ];

  const TrackingPages = [
    {
      title: 'Tracking',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Whatsapp',
          href: '/tracking/whatsapp',
          icon: <WhatsApp />
        },
        {
          title: 'Email',
          href: '/tracking/email',
          icon: <Email />
        },
        {
          title: 'Notifications',
          href: '/tracking/notifications',
          icon: <Notifications />
        },
        {
          title: 'Alerts Report',
          href: '/tracking/alerts-report-generator',
          icon: <People />
        }
      ]
    }
  ];
  const MarketingCampaignPages = [
    {
      title: 'Marketing',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Add New Campaign',
          href: '/add-marketing-campaign',
          icon: <SettingsIcon />
        },
        {
          title: 'View Campaigns',
          href: '/marketing-campaigns',
          icon: <VerifiedUser />
        },
        {
          title: 'View Users By Campaign',
          href: '/marketing-campaigns-user',
          icon: <People />
        },
        {
          title: 'View Link Click Data',
          href: '/marketing-campaigns-clicks',
          icon: <People />
        }
      ]
    }
  ];
  const PaymentPages = [
    {
      title: 'Payment Services',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Create New Payment Link',
          href: '/create-payment-link',
          icon: <SettingsIcon />
        },

        ...(localStorage.getItem('user_type') === 'ADMIN'? [
          {
            title: 'Manual Payment Gateway',
            href: '/manual-payment-gateway',
            icon: <SettingsIcon />
          }]:
          []
        )
      ]
    }
  ];
  const ExperiencePostsPages = [
    {
      title: 'Social Experiences Posts',
      icon: <Dashboard />,
      pages: [
        {
          title: 'View Under Review',
          href: '/social-experience-under-review',
          icon: <SettingsIcon />
        },
        {
          title: 'View All Experiences',
          href: '/active-social-experience',
          icon: <VerifiedUser />
        }
        // {
        //   title: 'View Closed',
        //   href: '/closed-social-experience',
        //   icon: <VerifiedUser />
        // },
        // {
        //   title: 'Add Job',
        //   href: '/add-job',
        //   icon: <VerifiedUser />
        // }
      ]
    }
  ];
  const JobPostsPages = [
    {
      title: 'Job Posts',
      icon: <Dashboard />,
      pages: [
        {
          title: 'View Under Review',
          href: '/jobs-under-review',
          icon: <SettingsIcon />
        },
        {
          title: 'View On Hold Jobs',
          href: '/jobs-on-hold/',
          icon: <HourglassFullRounded />
        },
        {
          title: 'View All Jobs',
          href: '/active-jobs',
          icon: <VerifiedUser />
        },
        {
          title: 'View Closed Jobs',
          href: '/closed-jobs',
          icon: <VerifiedUser />
        },
        {
          title: 'Add Job',
          href: '/add-job',
          icon: <VerifiedUser />
        }
      ]
    }
  ];
  const PitchPages = [
    {
      title: 'Pitch',
      icon: <Dashboard />,
      pages: [
        {
          title: 'View Under Review',
          href: '/pitch-under-review',
          icon: <SettingsIcon />
        },
        {
          title: 'All',
          href: '/pitch-all',
          icon: <SettingsIcon />
        }
      ]
    }
  ];
  const IntroPages = [
    {
      title: 'Intro',
      icon: <Dashboard />,
      pages: [
        {
          title: 'View Under Review',
          href: '/intro-under-review',
          icon: <SettingsIcon />
        }
      ]
    }
  ];
  const PromoCodePages = [
    {
      title: 'Promo Code',
      icon: <Dashboard />,
      pages: [
        {
          title: 'Add New Promo Code',
          href: '/add-promo-code',
          icon: <SettingsIcon />
        },
        {
          title: 'View Promo Codes',
          href: '/promo-codes',
          icon: <VerifiedUser />
        }
        // {
        //   title: 'View Users By Promo Codes ',
        //   href: '/promo-code-user',
        //   icon: <People />
        // }
      ]
    }
  ];
  const cardPages = [
    {
      title: 'Cards',
      icon: <ViewAgenda />,
      pages: [
        {
          title: 'Aux cards',
          href: '/aux-cards',
          icon: <ViewCarousel />
        }
      ]
    }
  ];

  const dbCorruptionPages = [
    {
      title: 'DB Corruptions',
      icon: <Error />,
      pages: [
        {
          title: 'Invalid country codes',
          href: '/invalid-country-codes',
          icon: <Error />
        }
      ]
    }
  ];

  const userPages = [
    {
      title: 'Users',
      icon: <People />,
      pages: [
        {
          title: 'Search User',
          href: '/search-user',
          icon: <Search />
        },
        {
          title: 'Search Hirers',
          href: '/search-hirers',
          icon: <Search />
        },
        {
          title: 'Search Social Profiles',
          href: '/search-social-user',
          icon: <Search />
        },
        {
          title: 'Active Users',
          href: '/active-users',
          icon: <VerifiedUser />
        },
        {
          title: 'Inactive Users',
          href: '/inactive-users',
          icon: <People />
        },
        {
          title: 'Latest SignUps',
          href: '/signups',
          icon: <PersonAdd />
        },
        {
          title: 'City Wise User Count',
          href: '/user-count-by-city',
          icon: <People />
        },
        {
          title: 'User Master Request',
          href: '/use-master-resquest',
          icon: <SettingsIcon />
        }
        // {
        //   title: 'Hire',
        //   href: '/Hire',
        //   icon: <SettingsIcon />
        // },
        // {
        //   title: 'JobPosting',
        //   href: '/JobPostings',
        //   icon: <SettingsIcon />
        // },
        // {
        //   title: 'NoJobs',
        //   href: '/NoJobs',
        //   icon: <SettingsIcon />
        // },
        // {
        //   title: 'JobsApplication',
        //   href: '/JobsApplication',
        //   icon: <SettingsIcon />
        // },
        // {
        //   title: 'ApplicationAccepted',
        //   href: '/ApplicationAccepted',
        //   icon: <SettingsIcon />
        // },
        // {
        //   title: 'ApplicationExpired',
        //   href: '/ApplicationExpired',
        //   icon: <SettingsIcon />
        // }
      ]
    }
  ];

  const masterPages = [
    {
      title: 'Master Data',
      icon: <SettingsIcon />,
      pages: [
        {
          title: 'Bussiness Service Type',
          href: '/business-service-type',
          icon: <SettingsIcon />
        },
        {
          title: 'Degree Level',
          href: '/degree-levels',
          icon: <SettingsIcon />
        },
        {
          title: 'Degree Field',
          href: '/degree-fields',
          icon: <SettingsIcon />
        },
        {
          title: 'Degree',
          href: '/degrees',
          icon: <SettingsIcon />
        },
        {
          title: 'Designation',
          href: '/designations',
          icon: <SettingsIcon />
        },
        {
          title: 'Fitness',
          href: '/fitnesses',
          icon: <SettingsIcon />
        },
        {
          title: 'Habit Frequency',
          href: '/habit_frequencies',
          icon: <SettingsIcon />
        },
        {
          title: 'Industry Type',
          href: '/industry_types',
          icon: <SettingsIcon />
        },
        {
          title: 'Interest',
          href: '/intersets',
          icon: <SettingsIcon />
        },
        {
          title: 'Political View',
          href: '/political_views',
          icon: <SettingsIcon />
        },
        {
          title: 'Purpose',
          href: '/purposes',
          icon: <SettingsIcon />
        },
        {
          title: 'Relationship Status',
          href: '/relationship_status',
          icon: <SettingsIcon />
        },
        {
          title: 'Relationship',
          href: '/relationships',
          icon: <SettingsIcon />
        },
        {
          title: 'Religion',
          href: '/religions',
          icon: <SettingsIcon />
        },
        {
          title: 'Skills',
          href: '/skills',
          icon: <SettingsIcon />
        },
        {
          title: 'Tz Points',
          href: '/tz-points',
          icon: <SettingsIcon />
        }
      ]
    }
  ];
  const userAdmin = localStorage.getItem('user_type') === 'ADMIN' || localStorage.getItem('user_type') === 'SALES';
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          cardPages={userAdmin ? cardPages : []}
          className={classes.nav}
          PaymentPages={userAdmin ? PaymentPages : []}
          // dbCorruptionPages={userAdmin ? dbCorruptionPages : []}
          ExperiencePostsPages={userAdmin ? ExperiencePostsPages : []}
          marketingCampaignPages={userAdmin ? MarketingCampaignPages : []}
          // TrackingPages={userAdmin ? TrackingPages : []}
          SalesDashboardPages={userAdmin ? SalesDashboardPages : []}
          // CommunicationPages={userAdmin ? CommunicationPages : []}
          // masterPages={userAdmin ? masterPages : []}
          promoCodePages={userAdmin ? PromoCodePages : []}
          pages={userAdmin ? pages : []}
          PitchPages={userAdmin ? PitchPages : []}
          IntroPages={userAdmin ? IntroPages : []}
          jobPostsPages={userAdmin ? JobPostsPages : []}
          userPages={
            userAdmin
              ? userPages
              : [
                {
                  title: 'Report',
                  icon: <People />,
                  pages: [
                    {
                      title: 'Report Generator',
                      href: '/report',
                      icon: <Search />
                    }
                  ]
                }
              ]
          }
        />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
