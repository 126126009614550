import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sendHttpCall } from 'shareService/httpService';
import { Search } from '@material-ui/icons';

import EditJobPost from '../ViewAllActiveJobs/ViewJobsUnderReview/EditJobPost';
// import { useNavigate } from 'react-router-dom';

import styles from './style2.module.scss';
import ReactModal from 'react-modal';
import ReactSelect from 'react-select';
import './style.scss';
import moment from 'moment';

import Pagination from '@material-ui/lab/Pagination';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import EditJobPostV2 from '../ViewAllActiveJobs/ViewJobsUnderReview/EditJobPostV2';
import JobCard from 'components/JobCard/JobCard';
import Loader from 'components/Loader';

const planNames = {
  HIRER_ADD_ON_1: 'Bronze',
  HIRER_ADD_ON_2: 'Silver',
  HIRER_ADD_ON_3: 'Gold',
  HIRER_PREMIUM: 'Enterprise',
  HIRER_ADD_ON_4: 'Basic',
  HIRER_ADD_ON_5: 'Premium',
  HIRER_ADD_ON_6: 'Enterprise',
  HIRER_ADD_ON_7: 'Starter',
  HIRER_ADD_ON_8: 'Basic',
  HIRER_ADD_ON_9: 'Premium',
  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_ADD_ON_10: 'Enterprise',

  HIRER_JOB_PLAN_1: 'Jobs Basic',

  HIRER_JOB_PLAN_2: 'Jobs Standard',

  HIRER_JOB_PLAN_3: 'Jobs Gold',

  HIRER_OFFER_PLAN_1: 'Offers Basic',

  HIRER_OFFER_PLAN_2: 'Offers Standard',

  HIRER_OFFER_PLAN_3: 'Offers Gold',

  HIRER_CV_PLAN_1: 'CV Basic',

  HIRER_CV_PLAN_2: 'CV Standard',

  HIRER_CV_PLAN_3: 'CV Gold'
};
const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case '₹ 10k - 25k':
    case '₹10k - 25k':
      return '₹ 1.2L - 3L';
    case '₹ 25k - 50k':
    case '₹25k - 50k':
      return '₹ 3L - 6L';
    case '₹ 50k - 75k':
      return '₹ 6L - 9L';
    case '₹ 75k - 100k':
      return '₹ 9L - 12L';
    case '₹ 100k - 150k':
      return '₹ 12L - 18L';
    case '₹ 150k - 200k':
      return '₹ 18L - 24L';
    case '₹ 200k - 300k':
      return '₹ 24L - 36L';
    case '₹ 300k - 500k':
      return '₹ 36L - 60L';
    case '>  ₹ 500k':
      return '> ₹ 60L';
    default:
      return monthlyVal;
  }
};
const addZero = (val) => (val < 10 ? `0${val}` : `${val}`);
const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? 'PM' : 'AM'}`;
};
const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? '/' : '-'}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? '/' : '-'}${addZero(date.getFullYear())}`;

const formatTime = (time) => {
  const date = new Date(time);

  return getTimeIn12Hours(date) + ', ' + formatDate(date);
};
export default function (props) {
  const classes = useStyles();
  // const navigate = useNavigate();
  const start = new Date();
  const prevQuery = useRef(null);
  const prevPage = useRef(null);
  const [editModalData, setEditModalData] = useState(null);
  const [query, setQuery] = useState('');
  const [rejectReason, setRejectReason] = useState(null);
  const [rejectReasonCustom, setRejectReasonCustom] = useState('');
  const [page, setPage] = useState(
    sessionStorage.getItem('viewReviewJobPage')
      ? parseInt(sessionStorage.getItem('viewReviewJobPage'), 10)
      : 1
  );
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [lastPage, setLastPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [selectedJobForDelete, setSelectedJobForDelete] = useState({});
  const [startDate, setStartDate] = useState(
    props.match.params.start_date ? props.match.params.start_date : '2019-01-01'
  );
  const [endDate, setEndDate] = useState(
    props.match.params.end_date
      ? props.match.params.end_date
      : moment(new Date(start)).format('YYYY-MM-DD')
  );

  const showToast = (msg) => {
    setToastMessage(msg);
  };
  const hideToast = () => {
    setToastMessage('');
  };
  useEffect(() => {
    if (toastMessage !== '') {
      setTimeout(hideToast, 2000);
    }
  }, [toastMessage]);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getData = () => {
    let pageVal = page;

    console.log(startDate, endDate);
    setLoading(true);
    let url = `/jobs/get_under_review?limit=${10}&page=${pageVal}&start=${startDate}&end=${endDate}`;
    if (query) {
      url += '&query=' + query;
    }
    sendHttpCall('GET', url, null, props).then((response) => {
      console.log(response);
      setData(response.data);
      let count = response.totalCount;
      setTotalCount(count);
      setLastPage(Math.ceil(count / 10));
      setLoading(false);
    });
  };
  const changeJobStatus = (
    offerId,
    approved,
    isFreeActive,
    message,
    deleteJob
  ) => {
    if (loading) return;

    if (!deleteJob && !approved && !message) {
      showToast('Cannot reject job without reason.');
      return;
    }

    setLoading(true);
    let url = `/jobs/update_job_post_status`;

    // Prepare the data object
    let data = {
      offerId,
      approved,
      message,
      delete: deleteJob
    };

    // Conditionally add isFreeActive if it is true
    if (isFreeActive) {
      data.isFreeActive = true;
      console.log('review: free active');
    }

    sendHttpCall('POST', url, data, props).then((response) => {
      console.log(response);
      setSelectedJob({});
      setSelectedJobForDelete({});
      if (deleteJob) {
        showToast('Successfully deleted job post.');
      } else {
        showToast(
          approved
            ? 'Successfully approved job post.'
            : 'Successfully rejected job post.'
        );
      }
      setRejectReason(null);
      setRejectReasonCustom('');
      setLoading(false);
      setTimeout(getData, 500);
    });
  };

  const setJobAsHold = (offerId) => {
    if (loading) return;
    setLoading(true);
    let url = `/jobs/update_job_post_as_on_hold`;
    sendHttpCall(
      'POST',
      url,
      {
        offerId
      },
      props
    ).then((response) => {
      console.log(response);
      showToast('Successfully Added On Hold.');
      setLoading(false);
      setTimeout(getData, 500);
    });
  };
  const approveJob = (offerId, isFreeActive = false) => {
    changeJobStatus(offerId, true, isFreeActive);
  };

  const setJobOnHold = (offerId) => {
    // console.log("offerId",offerId)
    setJobAsHold(offerId);
  };
  const rejectJob = (offerId, reason) => {
    changeJobStatus(offerId, false, false, reason, false);
  };
  const deleteJob = (offerId) => changeJobStatus(offerId, null, null, true);

  // useEffect(() => {
  //   if (prevPage.current !== page || prevQuery.current !== query) getData();
  //   // sessionStorage.removeItem('viewReviewJobPage');
  // }, [page, query]);

  useEffect(() => {
    getData();
    sessionStorage.setItem('viewReviewJobPage', page);
    // sessionStorage.removeItem('viewClosedJobPage');
  }, [page]);

  // for query
  useEffect(() => {
    getData();
  }, [query]);

  const [goToPage, setGoToPage] = useState('');

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    let pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= lastPage) {
      setPage(pageNumber); // Assuming `onChange` accepts the target page number as its parameter
      setGoToPage('');
    } else {
      alert('Please enter a valid page number');
    }
  };

  return (
    <div>
      <div className="jobs_under_review__container">
        <div className="jobs_under_review__header">Job Posts Under Review</div>
        <div className="active_jobs__search">
          <input
            type="text"
            placeholder="Search by user name/job title/company"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setQuery(e.target.value);
                //set the page to 1 so that when the query is searched the user is navigated to the first page instead of remaining on the current page
                setPage(1);
              }
            }}
          />
          <Search />
        </div>
        <div className="jobs_under_review__posts-container">
          {loading && (
            <div className="jobs_under_review__posts-loading">
              <Loader />
            </div>
          )}
          {!loading &&
            data.map((item) => (
              <JobCard
                status="UNDER_REVIEW"
                data={item}
                approveJob={approveJob}
                rejectJob={rejectJob}
                currentPage={page}
                deleteJob={deleteJob}
                refreshData={getData}
                showToast={showToast}
                setJobOnHold={setJobOnHold}
              />
            ))}
        </div>
        <div className="active_jobs__pagination">
          <div className="active_jobs__pagination-count">
            Total Count : {totalCount}
          </div>
          <Pagination
            size="large"
            color="#297bca"
            count={lastPage}
            page={page}
            onChange={handleChange}
          />
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              size="small"
              label="Go to page"
              variant="outlined"
              value={goToPage}
              onChange={handleGoToPageChange}
              inputProps={{ type: 'number', min: 1, max: lastPage }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleGoToPage();
                }
              }}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: '#297bca' }}
              onClick={handleGoToPage}>
              Go
            </Button>
          </Box>
        </div>
      </div>

      {toastMessage && (
        <div className="jobs_under_review__toast">{toastMessage}</div>
      )}
    </div>
  );
}
